// const BN_URL = 'http://localhost:8080/'
const BN_URL = 'https://baania.com/th/'

// exports.API_ENPOINT_URL = 'http://localhost:5000/'
exports.API_ENPOINT_URL = 'https://api-old.prop2share.com/'
exports.LANDING_URL = BN_URL + 'landing/'
exports.BN_LOGIN_URL = BN_URL + 'oauth/login?callback='
exports.BN_REGISTER_URL = BN_URL + 'oauth/register?callback='
exports.BN_ID = '202108040001'
exports.APP_ID = '166350818871842'
exports.APP_NAME = 'Prop2share'

exports.IGNORE_PAGES = [
    'callback',
    'how-it-work',
    'landing',
    'login',
    'login2'
]

exports.PROMOTE = {
    highlight: [1, 2],
    exclusive: 8,
    non_exc: 6
}

exports.BREAKPOINT = 'lg'

exports.ENABLED_DEVMODE = false
exports.ENABLED_USER_LOG = false

exports.CONTACT_CALL_NPA = '098-939-1567'
exports.CONTACT_CALL = '098-939-1567'
// exports.CONTACT_CALL = '097-174-8816'
exports.CONTACT_LINE = '@prop2share'
exports.CONTACT_EMAIL = 'contact@prop2share.com'
exports.CONTACT_FB = 'https://www.facebook.com/PROP2SHARE'

exports.ADS_DATA = [
    { dateStart: '10/05/2021 00:00:00', dataEnd: '10/10/2021 23:59:00', img: 'campaign-cgc.jpg' },
    { dateStart: '09/25/2021 00:00:00', dataEnd: '09/30/2021 23:59:00', img: 'campaign-2.jpg' },
    { dateStart: '09/21/2021 00:00:00', dataEnd: '09/23/2021 23:59:00', img: 'campaign-starbuck.jpg' },
    { dateStart: '01/01/2021 00:00:00', dataEnd: '01/01/2025 23:59:00', img: 'announce_01.jpg' }
]

exports.STAMP = {
    enabled: true,
    dateStart: '03/01/2022 00:00:00',
    dataEnd: '03/31/2022 23:59:00'
}

exports.FAKE_ACCESSTOKEN = 'MTQ0NjJkZmQ5OTM2NDE1ZTZjNGZmZjI3'
exports.FAKE_DATA_TEXT = "Previous versions wouldn't error out on interruptions from the client. A break in the connection from the client throws the error ECONNRESET in Node. I believe this is intended functionality for Node, so the fix (at least for me) was to handle the error, which I believe you did in unCaught exceptions. Although I handle it in the net.socket handler."
