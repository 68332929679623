import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { ENABLED_DEVMODE, ENABLED_USER_LOG } from '../config'
import { useAuth } from '../services/account'

export default function AppService() {

  const auth = useAuth()
  const { pathname } = useLocation()
  // const loc = window.location.pathname.split('/')
  const loc = window.location.hostname
  // console.log(window)

  // keep user log
  useEffect(() => {
    if (ENABLED_DEVMODE && loc.indexOf('localhost') > -1) return
    if (!ENABLED_USER_LOG) return
    auth.checkUserLog(pathname)
  }, [auth, pathname, loc])

  // auto scroll top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
