// import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'
import { FiUserCheck } from 'react-icons/fi'
import { GiClick, GiReceiveMoney } from 'react-icons/gi'
// import { useUtils } from '../services/utils'
import { BREAKPOINT } from '../config'
import Section from '../components/Section'
import Layout from '../components/Layout'
// import { data } from '../data/intro_qa.json'
import classnames from 'classnames'

// const path = `${process.env.PUBLIC_URL}/images/`
const benefit = [
  {
    name: 'แค่แชร์ก็ได้เงิน',
    desc: 'ด้วยระบบการทำงานที่ง่าย เพียงแค่แชร์ โครงการหรือโปรโมชั่น ไปยัง Social media ของคุณ คุณก็ได้รับส่วน แบ่งสูงถึง 2500 บาท / ล้าน',
    img: 'b1.png'
  },
  {
    name: <>คัดสรรโครงการ<span className='d-block'>ที่ราคาดีที่สุด</span></>,
    desc: 'เลือกโครงการที่สนใจและกดแชร์ลิงก์ เพื่อนำลิงก์ที่ได้ไปแชร์บนหน้าโซเชียลของคุณ',
    img: 'b2.png'
  },
  {
    name: <>พัฒนาต่อยอด<span className='d-block'>สร้างอาชีพ เกิดรายได้</span></>,
    desc: 'เรายังมีทีมงานนายหน้าคุณภาพ ที่พร้อมให้คำปรึกษาให้คุณสามารถเป็นนายหน้ามืออาชีพ เพื่อรับส่วนแบ่งการขายสูงสูดถึง 2.5%',
    img: 'b3.png'
  }
]
const step = [
  {
    step: 1,
    name: 'Login',
    desc: 'ล็อกอินเข้ามาในระบบ Prop2Share และเชื่อมกับโซเชียลต่างๆของคุณ',
    img: 'step1.png',
    icon: FiUserCheck
  },
  {
    step: 2,
    name: 'Share link',
    desc: 'เลือกโครงการที่สนใจและกดแชร์ลิงก์ เพื่อนำลิงก์ที่ได้ไปแชร์บนหน้าโซเชียลของคุณ',
    img: 'step2.png',
    icon: GiClick
  },
  {
    step: 3,
    name: 'Earn',
    desc: 'รอรับค่าส่วนแบ่งงานขาย เมื่อมีการปิดการขาย จากลิงก์ของคุณ',
    img: 'step3.png',
    icon: GiReceiveMoney
  }
]
const reviewer = [
  {
    image: "rvw1.jpg",
    text: "เป็นระบบที่ง่ายต่อการใช้งาน และเข้าใจง่ายค่ะ",
    name: "ผู้ใช้งานระบบ Prop2Share"
  },
  {
    image: "rvw2.jpg",
    text: "ชอบตรงที่ไม่จำกัดอายุ เด็กวัยรุ่นที่อยากหารายได้เสริมก็ทำได้ค่ะ",
    name: "ผู้ใช้งานระบบ Prop2Share"
  }
]

export default function Landing() {

  // const { urlify } = useUtils()

  return (
    <Layout navbar='absolute' height={80} showFooter>

      <div className='bn-section-s1'>
        <Container>
          <Row className={`flex-column flex-${BREAKPOINT}-row flex-${BREAKPOINT}-row-reverse mt-4`}>
            <Col className={`col-${BREAKPOINT}-7 position-relative`}>
              <img src={`${process.env.PUBLIC_URL}/images/house.png`} alt='cover' className='img-fluid' />
            </Col>
            <Col className={`pb-5 pt-${BREAKPOINT}-5 text-center text-${BREAKPOINT}-start`}>
              <h1 className='display-4 fw-bold mt-4 mb-4 text-secondary'>Prop2<span className='text-primary'>Share</span><span className='d-block'>แค่แชร์ก็ได้ตังค์</span></h1>
              <a href='/login' rel='noreferrer' className='btn btn-lg btn-primary bn-btn-gradient rounded-pill px-5'>เริ่มใช้งาน</a>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <div className='text-center mb-4'>
          <h2 id='benefit' className='pt-5 text-secondary'>สิทธิประโยชน์ของ<span className='d-block'>Prop2Share</span></h2>
        </div>
        <Row className={`row-cols-1 row-cols-${BREAKPOINT}-3`}>
          {
            benefit.map((val, key) => {
              return (
                <Col key={key}>
                  <div className='py-3 text-center'>
                    <img src={`${process.env.PUBLIC_URL}/images/${val.img}`} alt='step' height='100' className='mb-3 -w-100' />
                    <h6>{val.name}</h6>
                    <p className='px-4 text-center small'>{val.desc}</p>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>

      <div className='bn-section-s2 pb-5'>
        <Container>
          <div id='step' className='pt-5 text-center'>
            <h2 className='d-inline-block bg-white p-4 mb-3 rounded shadow text-secondary'>ขั้นตอนการสร้างรายได้</h2>
          </div>
          <Row className={`row-cols-1 row-cols-${BREAKPOINT}-3 d-none d-${BREAKPOINT}-flex`}>
            {
              step.map((val, key) => {
                return (
                  <Col key={key}>
                    <div className='py-3 text-center'>
                      <img src={`${process.env.PUBLIC_URL}/images/${val.img}`} alt='step' className='img-fluid' />
                      <div className='mb-2'>
                        <span className='badge bg-secondary rounded-pill'>{val.step}</span>
                      </div>
                      <h6>{val.name}</h6>
                      <p className='px-4 text-center small'>{val.desc}</p>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
          <Step items={step} className={`d-${BREAKPOINT}-none`} />
        </Container>
      </div>

      <Container className='py-5'>
        <Row className={`flex-column flex-${BREAKPOINT}-row flex-${BREAKPOINT}-row-reverse`}>
          <Col className={`col-${BREAKPOINT}-7`}>
            <img src={`${process.env.PUBLIC_URL}/images/earn.png`} alt='step' className='img-fluid' />
          </Col>
          <Col className={`pb-5 text-center text-${BREAKPOINT}-start`}>
            <h2 id='earn' className='pt-5 mb-4 text-secondary'>วิธีการรับเงิน</h2>
            <p className={`px-5 p-${BREAKPOINT}-0`}>การชำระเงินจะกระทำโดยเมื่อมีการปิดการขายเกิดขึ้นเจ้าหน้าที่จะทำการติดต่อกลับเพื่อขอรายละเอียดการรับเงินค่า Commission</p>
          </Col>
        </Row>
      </Container>

      <div className='text-center py-3'>
        <h2 className='text-secondary'>เสียงจากผู้ใช้จริง</h2>
      </div>
      <Section isSlide>
        <Row className={`row-cols-1 row-cols-${BREAKPOINT}-2 justify-content-${BREAKPOINT}-center mb-5`}>
          {
            reviewer.map((val, key) => {
              return (
                <Col key={key} className={classnames(
                  `col-${BREAKPOINT}-5`,
                  // key > 0 && 'mt-5'
                )}>
                  <div className='card shadow'>
                    <div className='card-body'>
                      <div className='ratio ratio-16x9'>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/${val.image}`}
                          alt='reviewer'
                          className='img-fluid rounded'
                          style={{ objectFit: 'cover' }}
                        />
                      </div>
                      <q className='bn-section-q pt-3'>{val.text}</q>
                    </div>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Section>

      <Container className='pt-3 pb-5'>
        <Row>
          <Col className='text-center'>
            <h2 id='earn' className='mb-4 text-secondary'>Clients</h2>
            <img src={`${process.env.PUBLIC_URL}/images/client.jpg`} alt='client' className='img-fluid' />
          </Col>
        </Row>
      </Container>

      <div className='py-5 bg-light'>
        <Container>
          <div className='py-4 text-center'>
            <h2 className='text-secondary mb-4 px-5'>“สร้างรายได้ง่าย ๆ กับ PROP2SHARE”</h2>
            <a href='/login' rel='noreferrer' className='btn btn-lg btn-primary bn-btn-gradient rounded-pill px-5'>เข้าสู่ระบบ</a>
          </div>
        </Container>
      </div>

    </Layout>
  )
}

const Step = ({ items = [], className }) => {
  return (
    <Carousel
      // fade
      // controls={false}
      className={classnames(
        'bn-carousel-banner',
        items.length < 2 && 'hide-control',
        className
      )}
    >
      {
        items.map((val, key) => {
          return (
            <Carousel.Item key={key}>
              <div className='-py-3 text-center'>
                <img src={`${process.env.PUBLIC_URL}/images/${val.img}`} alt='step' className='img-fluid' />
                <div className='mb-2'>
                  <span className='badge bg-secondary rounded-pill'>{val.step}</span>
                </div>
                <h6>{val.name}</h6>
                <p className='px-4 text-center small'>{val.desc}</p>
              </div>
            </Carousel.Item>
          )
        })
      }
    </Carousel>
  )
}
