export default function Media({ img, title, body }) {

  const price = new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(body).replace(/\D00$/, '')

  return (
    <div className="d-flex">
      <div className="flex-shrink-0 col-4">
        <img src={img} alt={title} className="img-fluid rounded" />
      </div>
      <div className="flex-grow-1 ms-3">
        <h6 className='mb-0 fw-normal'>{title}</h6>
        <small className='-text-muted'>{price}</small>
      </div>
    </div>
  )
}