import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import { FiAlertCircle, FiEdit3 } from 'react-icons/fi'
import { useAuth } from '../services/account'
import { BREAKPOINT, ADS_DATA } from '../config'

export default function DataResponse({ className }) {

  const [isCompletedProfile, setIsCompletedProfile] = useState(true)
  const [showAds, setShowAds] = useState(false)
  const { user, actionLog } = useAuth()

  const handleClose = () => {
    setShowAds(false)
  }

  useEffect(() => {
    if (showAds) return
    const isDisplayedAds = actionLog('ads1')
    // console.log(showAds, isDisplayedAds)
    if (isDisplayedAds) {
      setTimeout(() => { setShowAds(true) }, 3000)
    }
    // return () => {
    //   setShowAds(false)
    // }
  }, [showAds, actionLog])

  useEffect(() => {
    if (!isCompletedProfile) return
    if (user?.sent === null
      || user?.sent === ''
      || user?.phone === null
      || user?.phone === '') {
      setIsCompletedProfile(false)
    }
    // return () => {
    //   setIsCompletedProfile(false)
    // }
  }, [user, isCompletedProfile])

  return (
    <>
      {!isCompletedProfile && (
        <div className={className}>
          <Container>
            <div className='alert border mb-0 bn-small'>
              <FiAlertCircle size={24} className='me-2' />
              กรุณากรอก อีเมล์ และเบอร์โทรศัพท์, <span className='text-info'>เพื่อรับส่วนแบ่งรายได้</span>
              <Link to='/account' className={`-d-block -d-${BREAKPOINT}-inline mt-2 text-end stretched-link`}>
                <FiEdit3 size={16} className='ms-2 me-1' />อัพเดตโปรไฟล์
              </Link>
            </div>
          </Container>
        </div>
      )}
      <Modal
        centered
        // scrollable
        // size='lg'
        show={showAds}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        // dialogClassName={`align-items-end align-items-${BREAKPOINT}-center`}
        className='bn-modal-ads'
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <img id='img-campaign' src={adsPick(ADS_DATA)} alt='announce' className='img-fluid' />
        </Modal.Body>
      </Modal>
    </>
  )
}

function adsPick(ads) {
  const path = `${process.env.PUBLIC_URL}images/`
  const pick = ads.filter(val => {
    let check = new Date()
    check = Date.parse(check)
    const from = Date.parse(val.dateStart)
    const to = Date.parse(val.dataEnd)
    // console.log(check, from, to)
    return (check <= to && check >= from)
  })
  // console.log(pick)
  return `${path}${pick[0]?.img}`
}

DataResponse.defaultProps = {
  callback: () => { }
}
