import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FiMail, FiEdit3 } from 'react-icons/fi'
import Layout from '../components/Layout'
import Section from '../components/Section'
import { CONTACT_EMAIL } from '../config'

export default function Contact() {
  return (
    <Layout
      fixed
      withBack
      header='ติดต่อเรา'
    >
      <Section>
        <Row className='justify-content-center'>
          <Col lg={8}>
            <div className={'card overflow-hidden h-100'}>
              <div>
                <div className='ratio ratio-21x9 bg-light overflow-hidden'>
                  <img src={`${process.env.PUBLIC_URL}/images/contact.jpg`} alt='contact' />
                </div>
              </div>
              <div className="card-body py-4 d-flex flex-column justify-content-between">
                <h3 className="card-title text-center">
                  ติดต่อเรา
                </h3>
                <div className="card-text text-center">
                  <p className='small text-muted px-5'>หากท่านมีข้อสงสัย หรืออยากสอบถามข้อมูลเพิ่มเติม กรุณาส่งคำถามมาที่อีเมล์ด้านล่างนี้ เรายินดีและพร้อมให้ความช่วยเหลือ</p>
                  <p className='text-secondary'><FiMail size={24} /> {CONTACT_EMAIL}</p>
                  <p className='pt-3'>
                    <a href={`mailto:${CONTACT_EMAIL}`} rel='noreferrer' className='btn btn-primary rounded-pill px-5'><FiEdit3 /> เขียนอีเมล์</a>
                  </p>
                  {/* <div className="d-flex justify-content-between mb-3 small bn-disabled">
                  <small>{p}</small>
                  <small>แชร์ {shareCount} ครั้ง</small>
                </div>
                <div className='d-flex gap-2'>
                  <button onClick={onClick.call} className="btn btn-sm btn-outline-success rounded-pill w-100"><FiPhone /> นัดหมาย</button>
                  <button onClick={onClick.share} className="btn btn-sm btn-success rounded-pill w-100"><FiShare /> แชร์</button>
                </div> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Section>
    </Layout>
  )
}
