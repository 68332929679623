import { useState } from 'react'
import { FiCheckCircle, FiAlertCircle } from 'react-icons/fi'
import Layout from '../components/Layout'
import Section from '../components/Section'
import Profile from '../components/Profile'
import Loading from '../components/Loading'
import { useAuth } from '../services/account'
import { BREAKPOINT } from '../config'
import { useForm } from 'react-hook-form'

export default function Account() {

  const { user, updateUser } = useAuth()
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alert, setAlert] = useState(0)
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: user?.sent,
      phone: user?.phone
    }
  })

  const onSubmit = data => {
    setLoading(true)
    updateUser({ id: user?.id, ...data }, (res) => {
      setTimeout(() => {
        onAlert(res)
        setLoading(false)
      }, 3000)
    })
  }

  const onAlert = msg => {
    setShowAlert(true)
    setAlert(msg)
    setTimeout(() => {
      setShowAlert(false)
    }, 3000)
  }

  // console.log(user)

  return (
    <Layout
      fixed
      withBack
      header='บัญชีของฉัน'
    >
      <Section>
        <h1 className={`d-none d-${BREAKPOINT}-block h4 mb-4`}>บัญชีของฉัน</h1>
        <div className={`col-${BREAKPOINT}-8`}>
          {showAlert && <Form><Alert status={alert} /></Form>}
          <Profile
            useCol
            id={user?.id}
            name={user?.name}
            img={user?.picture}
            variant='light'
            className='mb-3'
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Form label='อีเมล์'>
              <input
                {...register('email', {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                })}
                type='email'
                className='form-control'
              />
              {errors.email && <small className='text-danger'>อีเมล์ไม่ถุกต้อง</small>}
            </Form>
            <Form label='เบอร์โทรฯ'>
              <input
                {...register('phone', {
                  required: true,
                  pattern: /\d+/,
                  minLength: 10
                })}
                type='text'
                maxLength={10}
                className='form-control'
              />
              {errors.phone && <small className='text-danger'>เบอร์โทรฯไม่ถูกต้อง, ตัวอย่างที่ถูกต้อง 0801234567</small>}
            </Form>
            <Form>
              <p className='small bn-fade'>กรุณากรอก อีเมล์ และเบอร์โทรศัพท์ของท่าน ให้ครบถ้วนสมบูรณ์ สำหรับให้เจ้าหน้าที่ติดต่อกลับหาท่าน เพื่อรับส่วนแบ่งรายได้</p>
              <input type="submit" value='อัพเดต' className='btn btn-primary px-4 rounded-pill' />
            </Form>
          </form>
        </div>
        {
          loading && <Loading />
        }
      </Section>
    </Layout>
  )
}

const Form = ({ label, children }) => {
  return (
    <div className='row g-0 mb-3 small'>
      {label && <label className={`col-${BREAKPOINT}-4 col-form-label text-${BREAKPOINT}-end pe-5`}>{label}</label>}
      <div className={label ? 'col' : `col offset-${BREAKPOINT}-4`}>
        {children}
      </div>
    </div>
  )
}

const Alert = ({ status, show }) => {
  return (
    <>
      {
        status
          ? <div className={`alert alert-success mb-0`}>
            <FiCheckCircle size={32} className='pe-2' /> อัพเดตข้อมูลเรียบร้อย
          </div>
          : <div className={`alert alert-danger mb-0`}>
            <FiAlertCircle size={32} className='pe-2' /> ไม่สามารถอัพเดตข้อมูลได้
          </div>
      }
    </>
  )
}
