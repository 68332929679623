import { Link, useLocation, useHistory } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavLink from 'react-bootstrap/NavLink'
import NavItem from 'react-bootstrap/NavItem'
import Dropdown from 'react-bootstrap/Dropdown'
import Container from 'react-bootstrap/Container'
import { FiArrowLeft, FiMail, FiCalendar, FiUnlock, FiHelpCircle, FiChevronRight, FiUser, FiMoreVertical, FiMenu, FiX } from 'react-icons/fi'
import Logo from './Logo'
import Avatar from './Avatar'
import Social from './Social'
import { topMenu } from '../routes'
import { useAuth } from '../services/account'
import { useUtils } from '../services/utils'
import { APP_NAME, BREAKPOINT } from '../config'
import classnames from 'classnames'

export default function Layout({ children, ...props }) {

  const { user, log, isLoggedIn, signout } = useAuth()
  const history = useHistory()
  const menuLayout = [
    {
      name: 'บัญชีของฉัน',
      path: '/account',
      icon: FiUser
    },
    {
      name: 'การนัดหมาย',
      path: '/appointment/active',
      icon: FiCalendar
    },
    {
      name: 'วิธีใช้งาน',
      path: '/qa',
      icon: FiHelpCircle
    },
    {
      name: 'ติดต่อเรา',
      path: '/contact',
      icon: FiMail
    },
    {
      name: 'ออกจากระบบ',
      icon: FiUnlock,
      onClick: () => {
        // auth.signout(() => history.push('/login'))
        signout(() => {
          history.replace(log?.accessBy[1] || '/')
          window.location.reload()
        })
      }
    }
  ]

  return (
    <>
      <Navbar
        // bg="transparent"
        variant={props.navbar === 'absolute' ? 'light' : 'light'}
        expand={BREAKPOINT}
        fixed={props.fixed && 'top'}
        className={classnames(
          props.navbar === 'absolute' ? 'bn-navbar-abs bg-transparent' : 'border-bottom',
          props.fixed && 'bn-navbar-fixed bg-white'
        )}
        style={
          props.height && {
            height: props.height
          }
        }
      >
        <Container className='-flex-wrap'>
          <Header
            title={props.header}
            withBack={props.withBack}
            isLoggedIn={isLoggedIn}
          >
            <Collapse
              menu={menuLayout}
              className={`d-none d-${BREAKPOINT}-flex`}
              classDropdown={`d-${BREAKPOINT}-none`}
            />
            <Profile
              id={user?.id}
              img={user?.picture}
              count={user?.count}
              className={classnames(
                'ms-auto d-flex',
                props.withBack && `d-none d-${BREAKPOINT}-flex`
              )}
              classAvatar={`d-none d-${BREAKPOINT}-flex`}
            />
            <DropdownDesktop
              menu={menuLayout}
              className={`d-none d-${BREAKPOINT}-block`}
            />
          </Header>
          {/* {isLoggedIn && (
            <Collapse
              menu={menuLayout}
              className={`d-none d-${BREAKPOINT}-flex`}
              classDropdown={`d-${BREAKPOINT}-none`}
            />
          )} */}
        </Container>
      </Navbar>
      <div className='d-flex flex-column flex-fill'>
        {children}
      </div>
      <Footer className={!props.showFooter && `d-none d-${BREAKPOINT}-block`} />
    </>
  )
}

const Header = ({ title, withBack, isLoggedIn, children }) => {

  const to = isLoggedIn ? '/' : '/login'

  return (
    <>
      {
        withBack && (
          <Link to={to} className={`d-${BREAKPOINT}-none`}>
            <FiArrowLeft size={24} className='me-2' />
          </Link>
        )
      }
      <Link to={to} className={`navbar-brand me-0 d-${BREAKPOINT}-none`}>
        {
          title
            ? <>{title}</>
            : <Logo size={32} />
        }
      </Link>
      <Link to={to} className={`navbar-brand d-none d-${BREAKPOINT}-inline`}>
        <Logo size={32} />
      </Link>
      {
        isLoggedIn
          ? <>
            {
              children
            }
            {
              withBack
                ? <span className={`p-3 d-${BREAKPOINT}-none`}></span>
                : <Navbar.Toggle aria-controls='basic-navbar-nav' className='border-0 me-n2'>
                  <FiMenu />
                </Navbar.Toggle>
            }
          </>
          : <>
            <Nav as='ul' className={`ms-auto d-none d-${BREAKPOINT}-flex small`}>
              <Nav.Item as='li' className='mx-2'>
                <a href='#benefit' className='nav-link'>สิทธิประโยชน์</a>
              </Nav.Item>
              <Nav.Item as='li' className='mx-2'>
                <a href='#step' className='nav-link'>ขั้นตอนการแชร์</a>
              </Nav.Item>
              <Nav.Item as='li' className='mx-2'>
                <a href='#earn' className='nav-link'>วิธีการรับเงิน</a>
              </Nav.Item>
            </Nav>
            <a href='/login' rel='noreferrer' className='btn btn-sm btn-primary bn-btn-gradient ms-3 px-4 rounded-pill'>Login or Sign Up</a>
          </>
      }
    </>
  )
}

const Profile = ({ id, img, count = 0, className, classAvatar }) => {
  const { uid } = useUtils()
  return (
    <div className={className}>
      <div className='d-flex align-items-center px-2'>
        <span className='bn-small me-1'>แชร์สะสม</span>
        <span className='text-info'>{count}</span>
      </div>
      <Link to='/account' className={classnames('d-flex align-items-center ms-3', classAvatar)}>
        <Avatar id={id} img={img} size={32} />
        {/* <span className='ms-2 bn-small text-muted'>{uid(id)}</span> */}
      </Link>
    </div>
  )
}

const Collapse = ({ menu, className, classDropdown }) => {

  const Link2 = ({ link, name }) => {
    const location = useLocation()
    const isActive = location.pathname.split('/')[1] === link.split('/')[1]
    const css = isActive ? ' active' : ''
    // console.log(location.pathname.split('/')[1], link.split('/')[1], css)
    return (
      <Link to={link} className={`nav-link${css} small`}>{name}</Link>
    )
  }

  return (
    <Navbar.Collapse id='basic-navbar-nav' className='-justify-content-end -w-100'>
      <Nav as='ul' className={className}>
        {topMenu.map((val, index) => (
          <Nav.Item as='li' key={index} className='ms-3'>
            {
              val.disabled
                ? <span className='nav-link bn-disabled'>{val.name}</span>
                : <Link2 link={val.link} name={val.name} />
            }
          </Nav.Item>
        ))}
      </Nav>
      <DropdownMobile menu={menu} className={classDropdown} />
    </Navbar.Collapse>
  )
}

const DropdownDesktop = ({ menu, className }) => {
  return (
    <Dropdown
      align='end'
      as={NavItem}
      className={classnames('no-caret', className)}
    >
      <Dropdown.Toggle as={NavLink} style={{ lineHeight: 1.5 }} >
        <FiMoreVertical size={24} />
      </Dropdown.Toggle>
      <Dropdown.Menu className='bn-navbar-dropdown'>
        {menu.map((val, index) =>
          val.onClick
            ? <Dropdown.Item key={index} onClick={val.onClick}>
              <val.icon size={20} className='me-3' />
              {val.name}
            </Dropdown.Item>
            : <Link key={index} to={val.path} className='dropdown-item'>
              <val.icon size={20} className='me-3' />
              {val.name}
            </Link>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const DropdownMobile = ({ menu, className }) => {
  return (
    <div className={classnames('bn-navbar-menu', className)}>
      <Container>
        <Navbar.Toggle aria-controls='basic-navbar-nav' className='border-0 ms-auto me-n2'><FiX /></Navbar.Toggle>
        <Nav as='ul'>
          {menu.map((val, index) => (
            <Nav.Item as='li' key={index}>
              <Link
                to={val.path || '/'}
                onClick={val.onClick || null}
                className='nav-link d-flex align-items-center'
              >
                <val.icon className='me-3' />
                {val.name}
                <FiChevronRight className='ms-auto' />
              </Link>
            </Nav.Item>
          ))}
        </Nav>
      </Container>
    </div>
  )
}

const Footer = ({ className }) => {
  return (
    <div className={classnames('bn-footer bg-dark text-white-50 py-4', className)}>
      <Container>
        <div className={`d-flex flex-column flex-${BREAKPOINT}-row justify-content-between align-items-center gap-2`}>
          <div>Copyright &copy; 2021 {APP_NAME} สงวนสิทธิ์ทุกประการ</div>
          {/* <ul className='list-inline mb-0 text-black-50'>
        <Linkout>นโยบายความเป็นส่วนตัว</Linkout>
        <Linkout>เงื่อนไขการบริการ</Linkout>
        <Linkout to='/contact'>ติดต่อเรา</Linkout>
      </ul> */}
          <div>
            <Social variant='dark' />
          </div>
        </div>
      </Container>
    </div>
  )
}
