import { useContext } from 'react'
import classnames from 'classnames'
import Container from 'react-bootstrap/Container'
import Accordion from 'react-bootstrap/Accordion'
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import Card from 'react-bootstrap/Card'
import { FiPlus, FiMinus } from 'react-icons/fi'
import Layout from '../components/Layout'
import Section from '../components/Section'
import { useUtils } from '../services/utils'
import { BREAKPOINT } from '../config'
import { data } from '../data/qa.json'

export default function Qa() {

  const { urlify } = useUtils()

  return (
    <Layout
      fixed
      withBack
      header='Q&A'
    >
      <div className='bn-gradient-1'>
        <Container>
          <div className='py-4'>
            <h1 className={`d-none d-${BREAKPOINT}-block mb-0`}>Q&A</h1>
            <p className='mb-0'>คำถามที่ถูกถามบ่อยที่สุด</p>
          </div>
        </Container>
      </div>
      <Section>
        <Accordion defaultActiveKey='0' className='mb-5'>
          {
            data.map((val, key) => {
              return (
                <Card key={key} className='py-2'>
                  <ContextAwareToggle eventKey={`${key}`}>
                    <h6 className={classnames(
                      'mb-0',
                      val.highlight && 'text-primary'
                    )}>
                      {val.q}
                    </h6>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey={`${key}`}>
                    <Card.Body className='pt-0'>
                      {
                        val.a.map((list, index) => {
                          return (
                            <div key={index} dangerouslySetInnerHTML={{ __html: urlify(list) }}></div>
                          )
                        })
                      }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )
            })
          }
        </Accordion>
      </Section>
    </Layout>
  )
}

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext)
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  )
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <Card.Header
      onClick={decoratedOnClick}
      className='d-flex justify-content-between align-items-center'
    >
      {children}
      {isCurrentEventKey ? <FiMinus /> : <FiPlus />}
    </Card.Header>
  )
}
