// import { FiInbox } from 'react-icons/fi'

export default function Empty({ Icon, children }) {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center h-100 py-5 rounded bg-light- bn-fade'>
      {/* <div className='flex-fill'> */}
      {Icon && <Icon size={64} className='bn-fade-' />}
      <small className='py-2 bn-fade-'>{children}</small>
      {/* </div> */}
    </div>
  )
}
