import classnames from 'classnames'

export default function Avatar({ id, img, size, className }) {

  const fakeAvatar = `https://avatars.dicebear.com/api/avataaars/${id}.svg`
  const avatar = img || fakeAvatar

  function getFakeAvatar(e, fake) {
    // console.log(e)
    e.target.onerror = null
    e.target.src = fake
  }

  return (
    <div
      className={classnames('bn-avatar', className)}
      style={{
        width: size,
        height: size,
      }}
    >
      <img
        src={avatar}
        alt='avatar'
        onError={e => getFakeAvatar(e, fakeAvatar)}
      />
    </div>
  )
}
