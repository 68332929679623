import axios from 'axios'
import { API_ENPOINT_URL } from '../config'

export default async function shared(url, job, agent, platform, cb) {

  // console.log('open', url, job, agent, platform)
  let res = false
  let data = {
    url: url,
    job: job,
    agent: agent,
    plat: platform
  }

  try {
    const insert = await axios.post(API_ENPOINT_URL + 'share', data)
    if (insert) {
      // console.log('shared')
      res = true
      cb()
    } else {
      // console.log('no share')
    }
  } catch (err) {
    console.log(err)
  }

  return res
}
