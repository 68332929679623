import HowItWork from './pages/HowItWork'
import Home from './pages/Home'
import Projects from './pages/Projects'
import Exclusive from './pages/Exclusive'
import NPA from './pages/NPA'
import Rental from './pages/Rental'
import Appointment from './pages/Appointment'
import Qa from './pages/Qa'
import Contact from './pages/Contact'
import Account from './pages/Account'
import Login from './pages/Login'
import Login2 from './pages/Login2'
import Logout from './pages/Logout'
import Landing from './pages/Landing'
import Callback from './services/callback'
import NoMatch from './pages/404'
import {
  // FiBookmark, 
  FiDollarSign,
  // FiLayers, 
  FiTrendingUp, FiUser, FiHelpCircle, FiMail,
  // FiCalendar 
} from 'react-icons/fi'
import { VscBookmark, VscCalendar, VscHome, VscLayers, VscTerminalTmux } from 'react-icons/vsc'

const routes = [
  {
    path: "/",
    exact: true,
    showAtTop: false,
    showAtApp: false,
    component: Home
  },
  {
    name: "ดีลพิเศษ",
    link: "/exclusive",
    path: "/exclusive",
    showAtTop: true,
    showAtApp: true,
    icon: VscBookmark,
    component: Exclusive
  },
  {
    name: "โครงการที่น่าสนใจ",
    link: "/projects",
    path: "/projects",
    showAtTop: true,
    showAtApp: true,
    icon: VscLayers,
    component: Projects
  },
  {
    name: "บ้านสวยมือสอง",
    link: "/npa",
    path: "/npa",
    showAtTop: true,
    showAtApp: true,
    disabled: false,
    icon: VscHome,
    component: NPA
  },
  {
    name: "ห้องเช่า",
    link: "/rental",
    path: "/rental",
    showAtTop: true,
    showAtApp: true,
    disabled: false,
    icon: VscTerminalTmux,
    component: Rental
  },
  {
    name: "การนัดหมาย",
    link: "/appointment/active",
    path: "/appointment/:state",
    showAtTop: false,
    showAtApp: true,
    // disabled: true,
    icon: VscCalendar,
    component: Appointment
  },
  {
    name: "รายได้",
    link: "/",
    path: "/",
    showAtTop: false,
    showAtApp: false,
    disabled: true,
    icon: FiTrendingUp
  },
  {
    name: "ถอนเงิน",
    link: "/",
    path: "/",
    showAtTop: false,
    showAtApp: false,
    disabled: true,
    icon: FiDollarSign
  },
  {
    name: "บัญชีของฉัน",
    link: "/account",
    path: "/account",
    showAtTop: false,
    showAtApp: false,
    icon: FiUser,
    component: Account
  },
  {
    name: "วิธีใช้งาน",
    link: "/qa",
    path: "/qa",
    showAtTop: false,
    showAtApp: false,
    icon: FiHelpCircle,
    component: Qa
  },
  {
    name: "ติดต่อเรา",
    link: "/contact",
    path: "/contact",
    showAtTop: false,
    showAtApp: false,
    icon: FiMail,
    component: Contact
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/login2",
    component: Login2
  },
  {
    path: "/logout",
    component: Logout
  },
  {
    path: "/callback",
    component: Callback
  },
  {
    path: "/how-it-work",
    component: HowItWork
  },
  {
    path: "/landing",
    component: Landing
  },
  {
    path: "*",
    component: NoMatch
  }
]

const component = routes.filter(data => data.component !== undefined)
const appMenu = routes.filter(data => data.showAtApp)
const topMenu = routes.filter(data => data.showAtTop)

export { routes, component, appMenu, topMenu }