import { FiShare } from "react-icons/fi";
import { SiLine } from "react-icons/si";
import { BREAKPOINT, CONTACT_LINE } from "../config";
import { useUtils } from "../services/utils";
import classnames from "classnames";

export default function CouponCard({
  img,
  projectName,
  price,
  onClick,
  className,
}) {
  const { thb } = useUtils();

  return (
    <div className={classnames("card overflow-hidden h-100", className)}>
      <div className="position-relative">
        <a href="/#" onClick={onClick.coupon}>
          <div className="ratio ratio-fb bg-light overflow-hidden">
            <img src={img} alt={projectName} />
          </div>
        </a>
      </div>
      <div className="card-body d-flex flex-column justify-content-between">
        <h5 className="card-title fw-normal mb-0">
          <a href="/#" onClick={onClick.coupon} className="link-dark">
            {projectName}
          </a>
        </h5>
        <div className="bn-small mb-1">{thb(price)}</div>
        <div className="bn-card-banner-text d-flex align-items-center gap-2">
          <div className="lh-1 text-info">
            <span className="bn-small">รับเงินการแชร์ขั้นต่ำ</span>
            <span className="bn-card-banner-earn d-block fw-bold">
              {thb(price / 2)}
            </span>
          </div>
          <div className="bn-card-banner-btn d-flex gap-2 ms-auto">
            <a
              href={`https://line.me/R/ti/p/${CONTACT_LINE}`}
              target="_blank"
              rel="noreferrer"
              className={`btn btn-sm btn-primary rounded-pill px-3 px-${BREAKPOINT}-3 text-truncate`}
            >
              <SiLine size='1.5em'/> เพิ่มเพื่อน
            </a>
            <button
              onClick={onClick.share}
              className={`btn btn-sm btn-secondary -bn-btn-gradient rounded-pill px-3 px-${BREAKPOINT}-4 text-truncate`}
            >
              <FiShare /> แชร์
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
