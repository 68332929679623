// import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FiUserCheck } from 'react-icons/fi'
import { GiClick, GiReceiveMoney } from 'react-icons/gi'
import { useUtils } from '../services/utils'
import { BREAKPOINT } from '../config'
import Section from '../components/Section'
import Layout from '../components/Layout'
import { data } from '../data/intro_qa.json'

const step = [
  {
    step: 1,
    name: 'Login',
    desc: 'ล็อกอินเข้ามาในระบบ Prop2Share และเชื่อมกับโซเชียลต่างๆของคุณ',
    icon: FiUserCheck
  },
  {
    step: 2,
    name: 'Share link',
    desc: 'เลือกโครงการที่สนใจและกดแชร์ลิงก์ เพื่อนำลิงก์ที่ได้ไปแชร์บนหน้าโซเชียลของคุณ',
    icon: GiClick
  },
  {
    step: 3,
    name: 'Earn Incentive หรือ Commission',
    desc: 'รอรับค่าส่วนแบ่งงานขาย เมื่อมีการปิดการขาย จากลิงก์ของคุณ',
    icon: GiReceiveMoney
  }
]

export default function HowItWork() {

  const { urlify } = useUtils()

  return (
    <Layout>
      <Section className='-mb-5'>
        {/* <div className='ratio ratio-21x9 bg-light overflow-hidden'> */}
        <img src={`${process.env.PUBLIC_URL}/images/announce_00.jpg`} alt='affiliate' className='img-fluid rounded-3' />
        {/* </div> */}
      </Section>
      <Section title='Prop2Share แค่แชร์ก็ได้ตังค์'>
        <p className='mb-4 small'>Prop2Share คือ โปรแกรมที่สามารถสร้างรายได้ให้กับทุกคนแบบง่ายๆ เพียงแค่คุณแชร์ ทางทีม Prop2Share เปิดโอกาสให้ทุกคนร่วมเป็น Partner เพื่อสร้าง Content บนหน้าโซเชียลของคุณเอง ไม่ว่าจะเป็นการริวิวหรือบอกต่อ ทั้งประกาศโครงการอสังหาฯ โปรโมชั่น และ แคมเปญที่น่าสนใจ เพียงนำลิงก์ของคุณไปวาง ทุกยอดขายจากลิงก์คุณ รับส่วนแบ่งการขายไปเลย! เป็นการหารายได้แบบไม่ต้องลงทุน แถมเพื่อนได้อสังหาฯ เราได้ส่วนแบ่งการขาย</p>
        <Row className={`row-cols-1 row-cols-${BREAKPOINT}-3`}>
          {
            step.map((val, key) => {
              return (
                <Col key={key}>
                  <div className='py-3 text-center'>
                    <div
                      className='d-inline-block position-relative rounded-pill bg-primary mb-3'
                      style={{ width: 48, height: 48 }}
                    >
                      <val.icon
                        size={56}
                        className='position-absolute'
                        style={{ top: '.25em', left: '.5em' }}
                      />
                    </div>
                    <div className='mb-2'>
                      <span class="badge bg-dark rounded-pill">{val.step}</span>
                    </div>
                    <h6>{val.name}</h6>
                    <p className='px-4 text-center small'>{val.desc}</p>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Section>
      <Section title='Prop2Share คืออะไร'>
        {
          data.map((val, key) => {
            return (
              <div key={key} className='mb-4'>
                <h6 className='mb-0-'>{val.q}</h6>
                <div className='small'>
                  {
                    val.a.map((list, index) => {
                      return (
                        <div key={index} dangerouslySetInnerHTML={{ __html: urlify(list) }}></div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </Section>
      <Section>
        <div className='position-relative mb-5 py-5 rounded-3 text-center bn-gradient-3'>
          <h4 className='mb-0 bn-fade text-uppercase'>สร้างรายได้ง่าย ๆ กับเรา</h4>
          <a href='/login' rel='noreferrer' className='stretched-link'>เข้าสู่ระบบ หรือ สมัครสมาชิก</a>
        </div>
      </Section>
    </Layout >
  )
}
