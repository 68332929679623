import { useRef } from 'react'
import Layout from '../components/Layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from '../components/CardBanner'
import PopUp from '../components/PopUp'
import Filter from '../components/Filter'
import Section from '../components/Section'
import useFetch from '../services/useFetch'
import useFilter from '../services/useFilter'
import usePop from '../services/usePop'
import { API_ENPOINT_URL, BREAKPOINT } from '../config'

export default function Exclusive() {

  const isMouted = useRef(true)
  const { data: projects, loading } = useFetch(`${API_ENPOINT_URL}projects`, isMouted, [])
  const { data, onSelect } = useFilter('Exclusive', projects)
  const { type, data: modalData, show, handleClose, handleOpen } = usePop()

  return (
    <>
      <Layout
        fixed
        withBack
        header='ดีลพิเศษ'
      >
        <Section className={`pb-${BREAKPOINT}-0`}>
          <h1 className={`h2 d-none d-${BREAKPOINT}-flex align-items-center`}>
            {/* <img src={`${process.env.PUBLIC_URL}images/harrison.png`} alt='harrison' className='bn-logo-hr me-2' /> */}
            ดีลพิเศษ สำหรับ Prop2Share
          </h1>
          <div className={`d-flex gap-3 gap-${BREAKPOINT}-5 flex-column flex-${BREAKPOINT}-row`}>
            <small>เพียงแค่แชร์โปรเจคท์นี้ลงใน Social Media ตามลิงค์ที่ให้ไว้ด้านล่าง คุณก็สามารถรับค่านายหน้าได้</small>
            <Filter options={['location', 'type', 'sort']} onSelect={onSelect} />
          </div>
        </Section>
        <Section loading={loading} isEmpty className='mb-5'>
          {data?.length && (
            <>
              <Row className={`row-cols-1 row-cols-${BREAKPOINT}-2`}>
                {
                  data.map((val, key) => {
                    return (
                      <Col key={key} className={`pb-4`}>
                        <Card
                          img={val.BANNERURL}
                          projectName={val.PROJECTNAME}
                          price={val.STARTINGPRICE}
                          shareCount={val.SHARETOTAL}
                          isSold={val.ISSOLDOUT}
                          onClick={handleOpen(val)}
                        />
                      </Col>
                    )
                  })
                }
              </Row>
            </>
          )}
        </Section>
      </Layout>
      <PopUp
        type={type}
        data={modalData}
        show={show}
        onClose={handleClose}
      />
    </>
  )
}
