import { FiShare } from "react-icons/fi";
import { SiLine } from "react-icons/si";
import classnames from "classnames";

export default function CouponInfoCard({
  className
}) {

  return (
    <div
      className={classnames("card border-0 overflow-hidden h-100", className)}
      aria-hidden="true"
    >
      <div className="bn-card-couponinfo">
        <h5>เงื่อนไขการใช้งาน</h5>
        <ul className="bn-med mb-1">
          <li>
            คูปองส่วนลดมูลค่า 100,000 บาท
            สำหรับการซื้ออสังหาริมทรัพย์ที่ร่วมรายการผ่าน www.prop2share.com
            เท่านั้น
            <br />* เฉพาะโครงการในดีลพิเศษ สำหรับ Prop2Share
            และโครงการที่น่าสนใจ เท่านั้น ไม่รวมโครงการบ้านสวยมือสองและห้องเช่า
          </li>
          <li>
            ระยะเวลาการใช้คูปอง ตั้งแต่วันที่ 1 เมษายน - 30 มิถุนายน 2565
          </li>
          <li>
            คูปองส่วนลด 1 สิทธิ์ สามารถใช้เป็นส่วนลด 100,000 บาท ต่อการจอง 1
            ห้อง เท่านั้น
          </li>
          <li>
            คูปองส่วนลดนี้ สามารถโอนสิทธิ์ให้ผู้อื่นได้
            แต่ไม่สามารถโอน/เปลี่ยน/แลก/ทอน เป็นเงินสดได้
          </li>
          <li>
            กรณีที่มีการยกเลิกการจอง ที่มีการใช้คูปองส่วนลดร่วมด้วย
            คูปองจะไม่สามารถนำกลับมาใช้ใหม่ได้
          </li>
          <li>
            คูปองส่วนลดนี้สามารถใช้ได้เพียงครั้งเดียว
            โดยผู้ใช้งานจะต้องแสดงคูปองส่วนลดกับพนักงานฝ่ายขายก่อนทำการจองเท่านั้น
          </li>
        </ul>
      </div>
    </div>
  );
}
