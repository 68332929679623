import { useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import { FiExternalLink } from 'react-icons/fi'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import JobInfo from './JobInfo'
import CouponInfo from './CouponInfo'
import CallInfo from './CallInfo'
import ShareInfo from './ShareInfo'
import DownloadInfo from './DownloadInfo'
import Media from "./Media"
import { useAuth } from '../services/account'
import classnames from 'classnames'

export default function PopUp({ type, data, show, onClose }) {

  const { user } = useAuth()

  function removeHTML(str) {
    const tmp = document.createElement("DIV")
    tmp.innerHTML = str
    return tmp.textContent || tmp.innerText || ""
  }

  const renderShare = () => {
    return (
      <ShareInfo
        jobID={data.JOBID}
        quote={data.SHAREQUOTE}
        placeholder={data.SUGGESTEDQUOTE}
        social={[data.FB, data.TW, data.LI, data.C]}
      />
    )
  }

  const renderLinkOut = (user) => {
    const link = `${data.C}${user?.id}`
    return (
      <a
        href={removeHTML(link)}
        target='_blank'
        rel='noreferrer'
        className='btn btn-light w-100 rounded-pill'
      >
        ดูข้อมูลโครงการ
        <FiExternalLink className='ms-2 mb-2' />
      </a>
    )
  }

    const renderCouponLinkOut = (user) => {
      const link =
        data.JOBTYPE === "Coupon" ? `${data.C}` : `${data.C}${user?.id}`;
      return (
        <a
          href={removeHTML(link)}
          target="_blank"
          rel="noreferrer"
          className="btn btn-light w-100 rounded-pill"
        >
          {data.JOBTYPE === "Coupon" ? "ดูข้อมูลคูปอง" : "ดูข้อมูลโครงการ"}
          <FiExternalLink className="ms-2 mb-2" />
        </a>
      );
    };

  const renderSwitch = () => {
    switch (type) {
      case 'job':
        return (
          <>
            <Cover img={data.BANNERURL} name={data.PROJECTNAME} />
            {
              !data.ISSOLDOUT && (
                <div className='mb-5'>
                  <h5 className='mb-3'>แชร์โครงการนี้ให้ทุกคนเห็น</h5>
                  {renderShare()}
                </div>
              )
            }
            <h5 className='mb-4'>ข้อมูลสำหรับคุณ</h5>
            <JobInfo
              //img={data.BANNERURL}
              projectName={data.PROJECTNAME}
              price={data.STARTINGPRICE}
              isRent={data.JOBTYPE === 'Rental'}
              shareCount={data.SHARETOTAL}
              status={data.PROJECTSTATUS}
              proptype={data.PROPERTYTYPE}
              developer={data.DEVELOPERNAME}
              address={data.PROJECTFULLADDRESS}
              //link={`${data.C}${user?.id}`}
              quote={<Quote placeholder={data.SUGGESTEDQUOTE} />}
              files={<Download data={data.DOWNLOADLINK} />}
              className='mb-4'
            />
            {renderLinkOut(user)}
            {/* {renderDownload()} */}
          </>
        )
      case 'call':
        return (
          <>
            <Header title='นัดชมโครงการ'>
              <Media img={data.BANNERURL} title={data.PROJECTNAME} body={data.STARTINGPRICE} />
            </Header>
            <CallInfo />
          </>
        )
      case 'share':
        return (
          <>
            <Header title='แชร์โครงการนี้ให้ทุกคนเห็น'>
              <Media img={data.BANNERURL} title={data.PROJECTNAME} body={data.STARTINGPRICE} />
            </Header>
            {renderShare()}
            {/* {renderDownload()} */}
          </>
        )
      case 'coupon':
        return (
          <>
            <Cover img={data.THUMBNAILURL} name={data.PROJECTNAME} />
            { (
              <div className="mb-5">
                <h5 className="mb-3">แชร์คูปองนี้ให้ทุกคนเห็น</h5>
                {renderShare()}
              </div>
            )}
            <h5 className="mb-4">ข้อมูลสำหรับคุณ</h5>
            <CouponInfo
              //img={data.BANNERURL}
              projectName={data.PROJECTNAME}
              price={data.STARTINGPRICE}
              isCoupon={data.JOBTYPE === "Coupon"}
              proptype={data.PROPERTYTYPE}
              //link={`${data.C}${user?.id}`}
              quote={<Quote placeholder={data.SUGGESTEDQUOTE} />}
              className="mb-4"
            />
            {renderCouponLinkOut(user)}
            {/* {renderDownload()} */}
          </>
        );
      // case 'sharecoupon' :
      //   return (<></>)
      default:
        return 'x'
    }
  }

  return (
    <Modal
      centered
      scrollable
      show={show}
      onHide={onClose}
      // backdrop="static"
      keyboard={false}
      // dialogClassName={`align-items-end align-items-${BREAKPOINT}-center`}
      className={classnames(
        'bn-modal',
        type === 'job' && 'close-start'
      )}
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>Modal title</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        {renderSwitch()}
      </Modal.Body>
      {/* <Modal.Footer>
      </Modal.Footer> */}
    </Modal>
  )
}

const Header = ({ title, children }) => {
  return (
    <>
      <h4 className="mb-4">{title}</h4>
      {children}
      <hr className="my-4" />
    </>
  )
}

const Cover = ({ img, name }) => {
  return (
    <div className="bn-modal-cover ratio ratio-fb bg-dark mb-4">
      <img src={img} alt={name} />
    </div>
  )
}

const Quote = ({ placeholder }) => {
  const targetPlaceholder = useRef(null)
  const [showPlaceholder, setShowPlaceholder] = useState(false)
  const onCopyPlaceholder = () => {
    setShowPlaceholder(true)
    setTimeout(() => {
      setShowPlaceholder(false)
    }, 3000)
  }
  return (
    <div className='-bn-blockquote bn-small'>
      <p className={classnames(
        'mb-0',
        showPlaceholder ? 'text-primary' : null
      )}>
        {placeholder}
        <br />
        <CopyToClipboard text={placeholder} onCopy={onCopyPlaceholder}>
          <button ref={targetPlaceholder} className='btn btn-sm btn-link'>คัดลอก</button>
        </CopyToClipboard>
        <Overlay target={targetPlaceholder.current} show={showPlaceholder} placement='bottom'>
          <Tooltip>คัดลอกแล้ว</Tooltip>
        </Overlay>
      </p>
    </div>
  )
}

const Download = ({ data }) => {
  if (data) {
    const files = data.split(',')
    return (
      <>
        {/* <div className='py-4 -border-top'> */}
        {/* <h5><FiLink className='mb-1' /> Files</h5> */}
        <DownloadInfo files={files} className='row row-cols-2 g-1' classImg='col' />
        {/* <small className='bn-small'>** กดที่รูปเพื่อดาวน์โหลด</small> */}
        {/* </div > */}
      </>
    )
  }
}
