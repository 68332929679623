import { CONTACT_CALL, CONTACT_LINE } from '../config'

export default function CallInfo({ className }) {
  return (
    <>
      <p className="mb-4">
        สำหรับนายหน้าที่จะนัดชมโครงการที่สนใจ <br />
        สามารถแจ้งโครงการที่สนใจผ่านแอพพลิเคชัน Line <br />
        โดยจะมีเจ้าหน้าที่ติดต่อกลับไป <br /><br />
        Add Line <a href={`https://line.me/R/ti/p/${CONTACT_LINE}`} target='_blank' rel='noreferrer'>{CONTACT_LINE}</a> <br />
        เบอร์โทร {CONTACT_CALL}
      </p>
      <div className="input-group">
        <input type="text" placeholder={CONTACT_CALL} className="form-control form-control-sm rounded" disabled />
        <a href={`tel:${CONTACT_CALL.replaceAll('-', '')}`} className="btn btn-sm btn-link bn-btn-copy">Call</a>
      </div>
    </>
  )
}
