import React from "react"
import classnames from 'classnames'
import { useUtils } from '../services/utils'

export default function CouponInfo({
  img,
  projectName,
  price,
  isRent,
  isCoupon,
  shareCount,
  status,
  proptype,
  developer,
  address,
  link,
  quote,
  files,
  className
}) {

  const { thb } = useUtils()
  const earnText = isRent ? 'รับเงินการแชร์ต่อสัญญาเช่า 1 ปี' : 'รับเงินการแชร์ขั้นต่ำ'
  const earnRate = isRent ? thb(price) : isCoupon ? thb(price/2) : `${thb(price / 1000000 * 2500)}++`
  const priceRent = isRent ? `${thb(price)}/เดือน` : thb(price)

  const desc = isCoupon ? [
    { title: 'คูปอง', val: projectName },
    { title: 'ราคา', val: <>{priceRent}</> },
    { title: 'ได้รับ', val: <span className='text-info'><small className='d-block bn-small'>{earnText}</small>{earnRate}</span> },
    // { title: 'แชร์', val: `${shareCount} ครั้ง` },
    // { title: 'สถานะ', val: status },
    { title: 'ประเภท', val: proptype },
    // { title: 'ผู้พัฒนา', val: developer },
    // { title: 'ที่อยู่', val: <small>{address}</small> },
    { title: 'ประโยค', val: quote },
  ] : [
    { title: 'โครงการ', val: projectName },
    { title: 'ราคา', val: <>{priceRent}</> },
    { title: 'ได้รับ', val: <span className='text-info'><small className='d-block bn-small'>{earnText}</small>{earnRate}</span> },
    // { title: 'แชร์', val: `${shareCount} ครั้ง` },
    // { title: 'สถานะ', val: status },
    { title: 'ประเภท', val: proptype },
    // { title: 'ผู้พัฒนา', val: developer },
    { title: 'ที่อยู่', val: <small>{address}</small> },
    { title: 'ประโยค', val: quote },
    { title: 'รูปภาพ', val: files },
    // { title: '', val: landing }
  ] 

  return (
    <>
      {/* {img && (
        <div className="bn-modal-cover ratio ratio-fb bg-dark mb-4">
          <img src={img} alt={projectName} />
        </div>
      )} */}
      {/* <h4 className='mb-4'>{projectName}</h4> */}
      <dl className={classnames('row g-0', className)}>
        {
          desc.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <dt className='col-3 bn-fade'>{data.title}</dt>
                <dd className='col-9 text-end'>{data.val}</dd>
                <hr />
              </React.Fragment>
            )
          })
        }
      </dl>
    </>
  )
}

// function removeHTML(str) {
//   const tmp = document.createElement("DIV")
//   tmp.innerHTML = str
//   return tmp.textContent || tmp.innerText || ""
// }
