import { useLocation, Redirect } from 'react-router-dom'
import { Base64 } from 'js-base64'
import { BN_ID } from '../config'
// import Loading from '../components/Loading'

export default function Callback() {

  const query = useQuery()
  const res = query.get('response')
  const token = query.get('token')
  const isCorrect = checkParams(token, res)
  const log = JSON.parse(localStorage.getItem(`p2sl`))
  const landing = log?.accessBy[1] || '/login'
  // console.log('callback >> ', log, landing)

  if (isCorrect) {
    let param = res.split('-')
    let userID = Base64.decode(param[0])
    let signedRequest = param[1]
    const data = {
      accessToken: token,
      signedRequest: signedRequest,
      userID: userID
    }
    sessionStorage.setItem(`bn_${BN_ID}`, JSON.stringify(data))
  } else {
    console.log('incorrect')
  }

  return <Redirect to={landing} />

}

function checkParams(token, res) {
  let isParamsCorrect = (res !== null && res !== '' && token !== null && token !== '')
  let isCorrect = false

  if (isParamsCorrect) {
    res = res.split('-')
    if (res.length === 2) {
      isCorrect = (res[0] !== null && res[0] !== '' && res[2] !== null && res[2] !== '')
    }
  }

  // console.log(isParamsCorrect, isCorrect)
  return isCorrect
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}
