import { useState } from 'react'
import { useHistory } from 'react-router-dom'
// import FacebookLogin from 'react-facebook-login'
import FacebookLogin from '../services/facebook'
import { SiFacebook } from 'react-icons/si'
import { useAuth } from '../services/account'
import { useUtils } from '../services/utils'
import Loading from '../components/Loading'
import Logo from '../components/Logo'
import Social from '../components/Social'
// import FakeLogin from '../components/FakeLogin'
import BaaniaLogin from '../components/BaaniaLogin'
import { APP_ID, BREAKPOINT, BN_ID } from '../config'

export default function Login() {

  const history = useHistory()
  const auth = useAuth()
  const [loading, setLoading] = useState(false)
  const { isApp } = useUtils()
  const useIsApp = false
  if (auth.msg) console.log(auth.msg)

  const onLoad = (cb) => {
    setLoading(true)
    setTimeout(() => {
      cb()
      setLoading(false)
    }, 3000)
  }

  const logout = () => {
    auth.signout(() => {
      console.log('sign off')
      history.replace('/login')
    })
  }

  // const responseFake = (response) => {
  //   onLoad(() => {
  //     auth.signin(response, 'default', () => {
  //       history.replace('/')
  //     })
  //   })
  // }

  const responseBaania = (response) => {
    onLoad(() => {
      auth.signin(response, 'bn', () => {
        history.replace('/')
      })
    })
  }

  const responseFacebook = (response) => {
    onLoad(() => {
      auth.signin(response, 'fb', () => {
        history.replace('/')
      })
    })
  }

  const accessBy = (via) => {
    // console.log(via)
    auth.saveAccessLog(via)
  }

  return (
    <div
      className='d-flex flex-column align-items-center -h-100'
    // style={{ minHeight: 800 }}
    >
      <div className='col-md-8' style={{ maxWidth: 560 }}>
        <div className={`p-${BREAKPOINT}-4 text-center`}>
          <div className={`pt-5 pt-${BREAKPOINT}-3 mb-3`}>
            <Logo size={102} />
            <span className='d-block text-secondary bn-small' style={{ letterSpacing: '1px' }}>ระบบนายหน้าออนไลน์สำหรับ Social Platform</span>
          </div>
          {
            useIsApp && isApp()
              ? (
                <div className='pt-4'>
                  {/* <button type='button' className='btn btn-lg btn-outline-primary bn-btn-login' onClick={otherBrowser}><FiGlobe size={24} className='me-2' />เปิดบนเบราว์เซอร์</button> */}
                  <div className='alert alert-warning p-4- small'>
                    {/* <FiGlobe size={24} className='me-2' /> */}
                    <strong>Please Open In Default Browser</strong>
                    <small className='d-block'>
                      กรุณาเปิดบนเบราว์เซอร์ หรือคัดลอกลิงก์ด้านล่างนี้</small>
                    <a href='https://prop2share.com' target='_blank' rel='noreferrer'><small className='d-block'>https://prop2share.com</small></a>
                  </div>
                </div>
              )
              : (
                <>
                  <p>
                    {
                      auth.error
                        ? <small className='text-danger'>ไม่สามารถเข้าสู่ระบบได้</small>
                        : !auth.isLoggedIn && <small className='text-muted invisible'>ล็อคอินเข้าสู่ระบบด้วยบัญชีของท่าน</small>
                    }
                  </p>
                  <div className='col-md-10 col-lg-8 mx-auto pb-5'>
                    <div className='d-grid gap-3'>
                      {
                        auth.isLoggedIn
                          ? (
                            <button type='button' className='btn btn-link' onClick={logout}>กลับหน้าแรก</button>
                          )
                          : (
                            <>
                              {/* <FakeLogin callback={responseFake} /> */}
                              {/* <span className='text-white-50'>หรือ</span> */}
                              {/* {
                                !isApp() && (
                                  <FacebookLogin
                                    appId={APP_ID}
                                    icon={(<SiFacebook size={24} className='me-2' />)}
                                    fields='name,email,picture'
                                    textButton='ดำเนินการต่อด้วย Facebook'
                                    cssClass='btn btn-lg bn-btn-fb bn-btn-login'
                                    callback={responseFacebook}
                                    onClick={() => accessBy('fb')}
                                    redirectUri={`${window.location.origin}/login`}
                                    disableMobileRedirect={!isApp()}
                                    version='11.0'
                                  />
                                )
                              } */}
                              <FacebookLogin
                                appId={APP_ID}
                                callback={responseFacebook}
                                onClick={() => accessBy('fb2')}
                              />
                              <BaaniaLogin
                                bnID={BN_ID}
                                textButton='ดำเนินการต่อด้วย Baania'
                                callback={responseBaania}
                                onClick={() => accessBy('bn')}
                              />
                            </>
                          )
                      }
                    </div>
                  </div>
                </>
              )
          }
          {/* </div> */}
          <div className='col-md-10 col-lg-8 mx-auto pb-5 text-center'>
            <a href='/landing' rel='noreferrer' className='btn btn-light bn-btn-login rounded-pill px-5'>เรียนรู้เพิ่มเติม</a>
          </div>
          <div className='mb-2 text-center bn-small'>
            ** หากพบปัญหาในการ Login
            <span className={`d-block d-${BREAKPOINT}-inline ms-1`} />
            ให้ท่านลองใช้ Browser อื่น ๆ เช่น Safari, Chrome, Firefox
            <small className='d-none bn-small bn-fade'>{window.location.origin}, {window.location.search}</small>
          </div>
          <div className='mb-5 text-center'>
            <Social />
          </div>
          {/* <p className='text-white text-break small'>{JSON.stringify(auth.msg)}</p> */}
        </div>
      </div>
      {loading && <Loading />}
    </div>
  )
}
