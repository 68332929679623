import classnames from 'classnames'
import Carousel from 'react-bootstrap/Carousel'
// import JobInfo from '../components/JobInfo'
// import { BREAKPOINT } from '../config'
// import { FiPhone, FiShare } from 'react-icons/fi'
// import { useAuth } from '../services/account'

export default function Highlight({ items = [], onClick, className }) {

  return (
    <Carousel
      // fade
      // controls={false}
      className={classnames(
        'bn-carousel-banner',
        items.length < 2 && 'hide-control'
      )}
    >
      {
        items.map((val, key) => {
          return (
            <Carousel.Item key={key}>
              <Item data={val} onClick={val.JOBTYPE == 'Coupon' ? onClick(val).coupon : onClick(val).job}>
                {/* <div className={`d-none d-${BREAKPOINT}-flex gap-3`}>
                  <button onClick={(e) => onClick(e, val, 'call')} className='btn -btn-sm btn-outline-primary rounded-pill px-4'><FiPhone /> นัดหมาย</button>
                  <button onClick={(e) => onClick(e, val, 'share')} className='btn -btn-sm btn-primary bn-btn-gradient rounded-pill px-5'><FiShare /> แชร์</button>
                </div> */}
              </Item>
            </Carousel.Item>
          )
        })
      }
    </Carousel>
  )
}

const Item = ({ data, onClick, children }) => {

  // const p = new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(data.STARTINGPRICE).replace(/\D00$/, '')
  // const { user } = useAuth()

  return (
    <>
      <div className={'ratio ratio-fb'}>
        <span>
          <a href='/#' onClick={onClick}>
            <img src={data.BANNERURL} alt={data.PROJECTNAME} />
          </a>
        </span>
      </div>
      {/* <Carousel.Caption>
        <JobInfo
          projectName={(
            <>
              <a href='/#' onClick={onClick} className='stretched-link link-light'>
                {data.PROJECTNAME}
              </a>
              <small className={`d-block d-lg-none bn-small text-white fw-normal lh-lg`}>{p}</small>
            </>
          )}
          price={data.STARTINGPRICE}
          shareCount={data.SHARETOTAL}
          status={data.PROJECTSTATUS}
          proptype={data.PROPERTYTYPE}
          developer={data.DEVELOPERNAME}
          address={data.PROJECTFULLADDRESS}
          link={`${data.C}${user?.id}`}
          className={`w-75 small d-none d-${BREAKPOINT}-flex`}
        />
        {children}
      </Carousel.Caption> */}
    </>
  )
}
