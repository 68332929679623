import { BREAKPOINT } from '../config'
import classnames from 'classnames'
import Avatar from './Avatar'
import { useUtils } from '../services/utils'

export default function Profile({ id, name, img, count, variant, useCol, className }) {

  const { uid } = useUtils()

  return (
    <div className={classnames(`row align-items-center`, className)}>
      <div className={classnames(
        'col-auto',
        useCol && `col-${BREAKPOINT}-4 pe-${BREAKPOINT}-5`
      )}>
        <Avatar id={id} img={img} className={useCol && 'ms-auto'} />
      </div>
      <div
        className={classnames(
          `col ps-0 ps-${BREAKPOINT}-2 text-truncate lh-1`,
          (variant === 'dark') ? 'text-white' : 'text-dark'
        )}
      >
        <span className='-fs-5'>{name}</span>
        <span className='d-block'>
          <span className='bn-small bn-fade'>ID: {uid(id)}</span>
          {/* {count > 0 && <span className='px-2 rounded-pill bg-light ms-auto'>ยอดแชร์สะสม: <span className='text-info'>{count}</span></span>} */}
        </span>
      </div>
      {/* {count > 0 && (
        <div className={`col-auto lh-1 ms-auto -border-start`}>
          <div className='text-end'>
            <span className='bn-small bn-fade'>แชร์สะสม</span>
            <span className='d-block fs-4 text-info'>{count}</span>
          </div>
        </div>
      )} */}
    </div>
  )
}

Profile.defaultProps = {
  count: 0
}
