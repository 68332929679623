import { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { FiChevronDown, FiFilter, FiCheck, FiList } from 'react-icons/fi'
import classnames from 'classnames'
import { BREAKPOINT } from '../config'

export default function Filter({ options = [], onSelect, className }) {

  const inputs = [
    {
      name: 'type',
      label: 'ประเภทอสังหาฯ',
      options: [
        { value: '', title: 'ทั้งหมด' },
        { value: 'ทาวน์โฮม', title: 'ทาวน์โฮม' },
        { value: 'คอนโด', title: 'คอนโด' },
        { value: 'บ้านเดี่ยว', title: 'บ้านเดี่ยว' }
      ],
      onClick: onSelect.type
    },
    {
      name: 'location',
      label: 'เขตพื้นที่',
      options: [
        { value: '', title: 'ทุกพื้นที่' },
        { value: 'กรุงเทพฯ', title: 'กรุงเทพฯ' },
        { value: 'ปทุมธานี', title: 'ปทุมธานี' },
        { value: 'นครปฐม', title: 'นครปฐม' },
        { value: 'นนทบุรี', title: 'นนทบุรี' },
        { value: 'สมุทรปราการ', title: 'สมุทรปราการ' }
      ],
      onClick: onSelect.location
    },
    {
      name: 'sort',
      label: 'เรียงโดย',
      options: [
        { value: '', title: 'ล่าสุด' },
        { value: 'asc', title: 'ราคาจากน้อยไปมาก' },
        { value: 'desc', title: 'ราคาจากมากไปน้อย' }
      ],
      onClick: onSelect.sort
    }
  ]

  const filterOptions = options.filter(item => item !== 'sort').map(val => inputs.find(({ name }) => name === val))
  const sortOptions = options.filter(item => item === 'sort').map(val => inputs.find(({ name }) => name === val))
  // console.log(filterOptions, sortOptions)

  return (
    <div className={classnames('bn-filter', className)}>
      <Row className={`flex-nowrap gx-2 gx-${BREAKPOINT}-3`}>
        <Col xs={6} className={`col-${BREAKPOINT}-auto`}>
          <Selector
            Icon={FiFilter}
            label='ตัวกรอง'
            split
            options={filterOptions}
          />
        </Col>
        {
          sortOptions.length > 0 && (
            <Col xs={6} className={`col-${BREAKPOINT}-auto`}>
              <Selector
                Icon={FiList}
                label='เรียงโดย'
                options={sortOptions}
                defaultOptions={sortOptions[0].options[0].title}
              />
            </Col>
          )
        }
      </Row>
    </div>
  )
}

// const Input = ({ type, label, options = [], onClick, className }) => {

//   const [title, setTitle] = useState(options[0]?.title)
//   // const [show, setShow] = useState(false)
//   // const showDropdown = () => setShow(!show)
//   const handleClick = (title, val) => {
//     setTitle(title)
//     onClick(val)
//   }

//   const renderTitle = () => {
//     return (
//       <div className='d-flex align-items-center p-2 pe-0 text-dark'>
//         <span className='lh-1 small'>
//           <label className='bn-small fw-light'>{label}</label>
//           <span className='d-block fw-bold'>
//             {title}
//           </span>
//         </span>
//         <FiChevronDown className='bn-icon ms-4 -text-secondary' />
//       </div>
//     )
//   }

//   return (
//     <NavDropdown
//       title={renderTitle()}
//       // align='end'
//       // renderMenuOnMount
//       autoClose='outside'
//       // show={show}
//       // onToggle={showDropdown}
//       // onMouseEnter={showDropdown}
//       // onMouseLeave={hideDropdown}
//       className={classnames('no-caret', className)}
//     >
//       {
//         options.map((val, key) => {
//           return (
//             <NavDropdown.Item
//               key={key}
//               onClick={() => handleClick(val.title, val.value)}
//               className='d-flex -justify-content-between align-items-center ps-4 pe-5 small'
//             >
//               {
//                 val.title === title
//                   ? <FiCheckCircle className='me-3 text-primary' />
//                   : <FiCircle className='me-3 text-dark' />
//               }
//               {val.title}
//             </NavDropdown.Item>
//           )
//         })
//       }
//     </NavDropdown>
//   )
// }

const Selector = ({ label, options = [], defaultOptions, Icon, split = false }) => {

  const isMultiple = options.length > 1
  const [active, setActive] = useState([])
  const [show, setShow] = useState(false)
  const [clear, setClear] = useState(false)
  const [title, setTitle] = useState(defaultOptions || '')

  const handleActive = (key, title, val) => {
    if (isMultiple) {
      options.map(() => {
        active[key] = (val !== '') ? title : null
        setActive(active)
        setTitle(active.filter(a => a !== null).join(', '))
        return true
      })
      setClear(false)
    } else {
      setTitle(title)
    }
  }
  const showDropdown = () => {
    setShow(!show)
  }
  const clearDropdown = () => {
    showDropdown()
    setClear(true)
  }
  const renderTitle = () => {
    return (
      <div className='d-flex align-items-center w-100 gap-2'>
        <span className='bn-filter_i1'>
          <Icon className='fs-5 bn-fade' />
        </span>
        <span className='lh-1 small overflow-hidden'>
          <label className={classnames(
            // 'text-truncate',
            title !== '' && 'bn-small fw-light'
          )}>
            {label}
          </label>
          {
            title !== '' && (
              <span className={classnames(
                'd-block text-truncate fw-bold small text-primary'
                // isMultiple && 'small'
              )}>
                {title}
              </span>
            )
          }
        </span>
        <span className='ms-auto'>
          <FiChevronDown className='bn-icon' />
        </span>
      </div>
    )
  }

  return (
    <NavDropdown
      title={renderTitle()}
      align='end'
      // renderMenuOnMount
      autoClose={isMultiple ? 'outside' : true}
      show={show}
      onToggle={showDropdown}
      // onMouseEnter={showDropdown}
      // onMouseLeave={hideDropdown}
      className={classnames(
        'no-caret',
        split && 'split',
        title !== '' && 'active'
      )}
    >
      {
        options.map((v, index) => {
          return (
            <div key={index} className='row g-2 mx-3 py-2 small'>
              {isMultiple && <label className='bn-small'>{v.label}</label>}
              <Items
                index={index}
                options={v.options}
                split={split}
                isClear={clear}
                onClick={v.onClick}
                onActive={handleActive}
              />
            </div>
          )
        })
      }
      {
        isMultiple && <div className='d-flex p-3 gap-2'>
          <button type='button' className='btn btn-sm btn-light rounded-pill w-100' onClick={clearDropdown}>ล้าง</button>
          <button type='button' className='btn btn-sm btn-secondary rounded-pill w-100' onClick={showDropdown}>ตกลง</button>
        </div>
      }
    </NavDropdown>
  )
}

const Items = ({ index, options = [], split, isClear, onActive, onClick }) => {

  const [title, setTitle] = useState(options[0].title)

  const handleClick = (title, val) => {
    // console.log(index)
    setTitle(title)
    onClick(val)
    onActive(index, title, val)
  }

  useEffect(() => {
    if (isClear) {
      setTitle(options[0].title)
      onClick('')
      onActive(index, options[0].title, '')
    }
  }, [index, options, isClear, onClick, onActive])

  return (
    <>
      {
        options.map((val, key) => {
          return (
            <div key={key} className={split ? 'col-6' : 'col-12'}>
              <NavDropdown.Item
                onClick={() => handleClick(val.title, val.value)}
                className={classnames(
                  'd-flex align-items-center border px-2 py-1 small rounded-1',
                  val.title === title && 'border-primary text-primary'
                )}
              >
                {/* {
                  val.title === title
                    ? <FiCheckCircle className='me-2 text-info' />
                    : <FiCircle className='me-2' />
                } */}
                {val.title}
                {val.title === title && <FiCheck className='ms-auto text-secondary' />}
              </NavDropdown.Item>
            </div>
          )
        })
      }
    </>
  )
}
