import { useState, useEffect } from 'react'
import axios from 'axios'

export default function useFetch(url, ref, initialValue) {

  const [data, setData] = useState(initialValue)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  // console.log('fetch >> ', data)

  useEffect(() => {

    if (ref.current) {
      (async () => {
        // console.log('new fetch')
        try {
          const response = await axios(`${url}`)
          setData(response.data)
        } catch (err) {
          setError(err)
        } finally {
          setLoading(false)
        }
      })()
    }

    return () => {
      ref.current = false
    }

  }, [url, ref])

  return { data, error, loading }
}
