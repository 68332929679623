import { useState, useEffect } from 'react'

export default function useFilter(defaultFilter, initialValue = []) {

  const [data, setData] = useState(initialValue)
  const [propType, setPropType] = useState('')
  const [locate, setLocate] = useState('')
  const [sort, setSort] = useState('')
  const onType = (val) => setPropType(val)
  const onLocate = (val) => setLocate(val)
  const onSort = (val) => setSort(val)
  // console.log('filter >> ', data)

  useEffect(() => {

    function filtering(d) {
      return (
        d.JOBTYPE === defaultFilter
        && (propType !== '' ? d.PROPERTYTYPE === propType : true)
        && (locate !== '' ? d.PROJECTPROVINCENAME === locate : true)
      )
    }

    function sorting(a, b) {
      switch (sort) {
        case 'asc':
          return a.STARTINGPRICE - b.STARTINGPRICE

        case 'desc':
          return b.STARTINGPRICE - a.STARTINGPRICE

        default:
          return true
      }
    }

    (async () => {
      try {
        const res = await initialValue
          .filter(filtering)
          .sort(sorting)
          .map((val) => val)
        // console.log('res', res)
        setData(res)
      } catch (err) {
        console.log(err)
      }
    })()

    // console.log(data)

    // clean up
    return () => {
      setData(initialValue)
    }

  }, [defaultFilter, initialValue, locate, propType, sort])

  return {
    data,
    onSelect: {
      location: onLocate,
      type: onType,
      sort: onSort
    }
  }

}
