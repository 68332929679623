import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import Empty from '../components/Empty'
import { FiInbox, FiArrowRight } from 'react-icons/fi'
import { BREAKPOINT } from '../config'
import classnames from 'classnames'

export default function Section({
  title,
  desc,
  viewMore,
  showMoreButton,
  fluid,
  centered,
  bordered,
  isEmpty,
  isSlide,
  loading,
  children,
  className
}) {
  return (
    <div
      className={classnames(
        `py-4 py-${BREAKPOINT}-5 position-relative`,
        centered ? 'h-75' : null,
        className
      )}
      style={{
        borderBottom: bordered && '3px solid rgba(0,0,0,.05)'
      }}
    >
      {title && (
        <div className='bn-section mb-3'>
          <Container fluid={fluid}>
            <div className={classnames(
              'd-flex justify-content-between align-items-center mb-2'
            )}>
              <h2 className={classnames('mb-0')}>
                {viewMore
                  ? <Link to={viewMore} className='d-flex align-items-center link-dark'>{title}</Link>
                  : <span className='d-flex align-items-center link-dark'>{title}</span>
                }
              </h2>
              {viewMore && <Link to={viewMore} className='bn-more'>ดูเพิ่ม</Link>}
            </div>
            {desc && <small>{desc}</small>}
          </Container>
        </div>
      )}
      <div className={classnames(
        isSlide && 'bn-section-slide'
      )}>
        <Container fluid={fluid}>
          {
            loading
              ? <Empty>กำลังโหลด...</Empty>
              : isEmpty && !children
                ? <Empty Icon={FiInbox}>ไม่พบข้อมูล</Empty>
                : <>
                  {children}
                  {
                    showMoreButton && (
                      <div className={`pt-3 text-center d-none d-${BREAKPOINT}-block`}>
                        <Link to={viewMore} className='btn btn-light rounded-pill px-5'>ดูเพิ่มเติม <FiArrowRight className='mb-1' /></Link>
                      </div>
                    )
                  }
                </>
          }
        </Container>
      </div>
    </div>
  )
}

Section.defaultProps = {
  link: '/',
  centered: false,
  showMoreButton: false,
  isEmpty: false,
  isSlide: false,
  className: ''
}
