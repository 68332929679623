import { FiMail, FiPhone } from 'react-icons/fi'
import { SiFacebook, SiLine } from 'react-icons/si'
import { CONTACT_CALL, CONTACT_LINE, CONTACT_EMAIL, CONTACT_FB } from '../config'
import classnames from 'classnames'

export default function Social({ variant }) {

  const contact = [
    // {
    //   link: `tel:${CONTACT_CALL}`,
    //   icon: FiPhone
    // },
    {
      link: `mailto:${CONTACT_EMAIL}`,
      icon: FiMail
    },
    {
      link: `https://line.me/R/ti/p/${CONTACT_LINE}`,
      icon: SiLine
    },
    {
      link: CONTACT_FB,
      icon: SiFacebook
    }
  ]

  return (
    <>
      {
        contact.map((val, key) => {
          return (
            <a
              key={key}
              href={val.link}
              target='_blank'
              rel='noreferrer'
              className={classnames(
                'd-inline-block lh-1 p-2',
                variant === 'dark' ? 'link-light' : 'link-dark'
              )}
            >
              <val.icon />
            </a>
          )
        })
      }
    </>
  )
}
