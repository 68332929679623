function NoMatch() {
    return (
        <div className='d-flex vh-100 justify-content-center align-items-center flex-column bg-light'>
            <h1 className='display-1'>404</h1>
            <p>Oops! Page not be found.</p>
            <a href='/' className='btn btn-link'>Return to Home</a>
        </div>
    )
}

export default NoMatch