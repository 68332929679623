import { useState, useEffect } from 'react'
import { SiFacebook } from 'react-icons/si'

export default function FacebookLogin({ appId, callback, onClick }) {

  const [FB, setFB] = useState('')

  const onLogin = () => {
    // console.log(navigator)
    if (!FB) return
    console.log('fb-ready')
    FB.getLoginStatus(response => {
      if (response.status === 'connected') {
        // console.log('fb res >> ', response)
        facebookLoginHandler(response)
      } else {
        FB.login(facebookLoginHandler, { scope: 'public_profile,email' })
      }
    })
    onClick()
  }

  const onLogout = () => {
    FB.logout(function (response) {
      console.log('logout >> ', response)
      // user is now logged out
    })
  }

  const facebookLoginHandler = (response) => {
    if (response.status === 'connected') {
      FB.api('/me?fields=name,email,picture', userData => {
        let result = {
          ...response.authResponse,
          ...userData
        }
        callback(result)
      })
    } else {
      console.log('fb not connect')
    }
  }

  useEffect(() => {

    const script = document.createElement('script')
    const scriptInner = document.createElement('script')

    function initializeFacebookLogin() {
      setFB(window.FB)
    }

    script.src = 'https://connect.facebook.net/th_TH/sdk.js'
    script.defer = true
    script.async = true
    // script.crossorigin = 'anonymous'
    // script.nonce = 'FQ2JqUNM'
    scriptInner.innerHTML = `window.fbAsyncInit = function () {
      FB.init({
        appId: '${appId}',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v11.0'
      })
      var fbInitEvent = new Event('FBObjectReady')
      document.dispatchEvent(fbInitEvent)
    }`
    // document.body.appendChild('<div id="fb-root"></div>')
    document.body.appendChild(script)
    document.body.appendChild(scriptInner)
    document.addEventListener('FBObjectReady', initializeFacebookLogin)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(scriptInner)
      document.removeEventListener('FBObjectReady', initializeFacebookLogin)
    }

  }, [appId])

  return (
    <>
      {/* <div
        data-width='100%'
        data-size='large'
        data-button-type='continue_with'
        data-layout='rounded'
        data-auto-logout-link='true'
        data-use-continue-as='false'
        data-onlogin={onLogin()}
        className='fb-login-button'
      > */}
      <button
        type='button'
        onClick={onLogin}
        className='btn btn-lg bn-btn-fb bn-btn-login'
      >
        <SiFacebook size={24} className='me-2' />ดำเนินการต่อด้วย Facebook
      </button>
      {/* <button
        type='button'
        onClick={onLogout}
        className='btn'
      >
        logout
      </button> */}
      {/* </div> */}
    </>
  )
}

FacebookLogin.defaultProps = {
  callback: () => { },
  onClick: () => { },
}
