import { useRef } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FiArrowRight } from 'react-icons/fi'
import Layout from '../components/Layout'
// import Card from '../components/Card'
import CouponCard from '../components/CouponCard'
import CouponInfoCard from '../components/CouponInfoCard'
import Card from '../components/CardBanner'
import PopUp from '../components/PopUp'
import Profile from '../components/Profile'
import Highlight from '../components/Highlight'
import Section from '../components/Section'
// import AppointList from '../components/AppointList'
import DataResponse from '../components/DataResponse'
import StampCard from '../components/StampCard'
import { useAuth } from '../services/account'
import useFetch from '../services/useFetch'
import useFilter from '../services/useFilter'
import usePop from '../services/usePop'
import { useUtils } from '../services/utils'
import { API_ENPOINT_URL, BREAKPOINT, PROMOTE, STAMP } from '../config'
import { appMenu } from '../routes'
import classnames from 'classnames'

export default function Home() {

  const isMouted = useRef(true)
  const { user } = useAuth()
  const { inTime } = useUtils()
  const { data, loading } = useFetch(`${API_ENPOINT_URL}projects`, isMouted, [])
  // const { data: appointList, loading: appointLoading } = useFetch(`${API_ENPOINT_URL}appointment/agent/${user?.id}?state=ACTIVE`, isMouted, [])
  const { data: dataExclusive } = useFilter('Exclusive', data)
  const { data: dataNonEx } = useFilter('Non-Exclusive', data)
  const { data: dataNPA } = useFilter('NPA', data)
  const { data: dataRental } = useFilter('Rental', data)
  const { data: dataCoupon } = useFilter('Coupon', data)

  const dataHighlight = [...data
      ?.filter((data) => PROMOTE.highlight.includes(data.ISHIGHLIGHT))
      .map((val) => val)]
  dataHighlight.push(
    ...dataCoupon
  );
  const dataExclusiveFirst = dataExclusive?.filter(data => data.ISHIGHLIGHT <= PROMOTE.exclusive).map(val => val)
  const { type, data: modalData, show, handleClose, handleOpen } = usePop()
  const couponlength = dataCoupon.length
  return (
    <>
      <Layout
        // fixed
        navbar='absolute'
      >
        <div className={`bn-profile style-1 d-${BREAKPOINT}-none`}>
          <div
            className='bn-profile_image'
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}'/images/header.jpg')`
            }}
          />
          <Container className='position-relative' style={{ zIndex: 1 }}>
            <Profile
              id={user?.id}
              name={user?.name}
              img={user?.picture}
              count={user?.count}
            // variant='dark'
            // className='pb-3'
            />
          </Container>
        </div>
        <div className={`py-${BREAKPOINT}-4-`}>

          <DataResponse className='py-4' />

          {/* <Section title='Services' className={`d-${BREAKPOINT}-none`}>
            <div className={`d-flex flex-wrap row-cols-3`}>
              {
                appMenu.map((val, index) => {
                  return (
                    <div
                      key={index}
                      className={'bn-app-menu'}
                      style={{
                        opacity: val.disabled ? .25 : 1
                      }}
                    >
                      <val.icon size={32} className='text-secondary' />
                      {
                        val.disabled
                          ? <span className='d-block'>{val.name}</span>
                          : <Link to={val.link || '/'} className='d-block stretched-link bn-small'>{val.name}</Link>
                      }
                    </div>
                  )
                })
              }
            </div>
          </Section> */}

          {
            (STAMP.enabled && inTime(STAMP.dateStart, STAMP.dataEnd)) && (
              <Section>
                <StampCard />
              </Section>
            )
          }

          <Section
            title='Weekly Highlight'
            loading={loading}
            isEmpty
            className='bg-light pb-5'
          >
            {dataHighlight?.length > 0 && (
              <Highlight items={dataHighlight} onClick={handleOpen} />
            )}
          </Section>
          <Section
            // title='Services'
            bordered
            className={`d-${BREAKPOINT}-none`}
          >
            <div className={`d-flex flex-wrap row-cols-3`}>
              {
                appMenu.map((val, index) => {
                  return (
                    <div
                      key={index}
                      className={'bn-app-menu'}
                      style={{
                        opacity: val.disabled ? .25 : 1
                      }}
                    >
                      <val.icon size={32} className='text-secondary' />
                      {
                        val.disabled
                          ? <span className='d-block'>{val.name}</span>
                          : <Link to={val.link || '/'} className='d-block stretched-link bn-small'>{val.name}</Link>
                      }
                    </div>
                  )
                })
              }
            </div>
          </Section>
          <Section
            title={(<>คูปองส่วนลด</>)}
            desc='เพียงแค่แชร์คูปองนี้ลงใน Social Media ตามลิงค์ที่ให้ไว้ด้านล่าง รับไปเลย 50 บาทต่อจำนวนใบที่คุณขายได้'
            loading={loading}
            bordered
          >
            <Row className={`row-cols-1 row-cols-${BREAKPOINT}-2`}>
                {
                  dataCoupon.map((val, index) => {
                    return (
                      <Col key={index} className={`pb-${BREAKPOINT}-4`}>
                        <CouponCard
                          img={val.THUMBNAILURL}
                          projectName={val.PROJECTNAME}
                          price={val.STARTINGPRICE}
                          onClick={handleOpen(val)}
                          className="bn-card-banner"
                        />
                      </Col>
                      
                    );
                  })
                  
                }
                <Col key={couponlength} className={`pb-${BREAKPOINT}-4`} aria-hidden={true}>
                  <CouponInfoCard
                  className="bn-card-banner"/>
                </Col>
                
              </Row>
            
          </Section>
          <Section
            title={(
              <>
                {/* <img src={`${process.env.PUBLIC_URL}images/harrison_s.png`} alt='harrison' className='bn-logo-hr me-2' /> */}
                ดีลพิเศษ สำหรับ Prop2Share
              </>
            )}
            desc='เพียงแค่แชร์โปรเจคท์นี้ลงใน Social Media ตามลิงค์ที่ให้ไว้ด้านล่าง คุณก็สามารถรับค่านายหน้าได้'
            viewMore='/exclusive'
            showMoreButton
            loading={loading}
            bordered
            isEmpty
            isSlide
          >
            {dataExclusiveFirst?.length > 0 && (
              <Row className={`row-cols-1 row-cols-${BREAKPOINT}-2`}>
                {
                  dataExclusiveFirst.map((val, index) => {
                    return (
                      <Col key={index} className={`pb-${BREAKPOINT}-4`}>
                        <Card
                          img={val.BANNERURL}
                          projectName={val.PROJECTNAME}
                          price={val.STARTINGPRICE}
                          shareCount={val.SHARETOTAL}
                          isSold={val.ISSOLDOUT}
                          onClick={handleOpen(val)}
                          className='bn-card-banner'
                        />
                      </Col>
                    )
                  })
                }
              </Row>
            )}
            {/* <div className={`py-3 text-center d-none d-${BREAKPOINT}-block`}>
              <Link to='/exclusive' className='btn btn-light rounded-pill px-5'>ดูดีลพิเศษเพิ่ม<FiArrowRight className='ms-1 mb-1' /></Link>
            </div> */}
          </Section>

          <Section
            title={(
              <>
                โครงการที่น่าสนใจ
              </>
            )}
            viewMore='/projects'
            showMoreButton
            loading={loading}
            isEmpty
            isSlide
            bordered
          >
            {dataNonEx?.length > 0 && (
              <Row className={`row-cols-1 row-cols-${BREAKPOINT}-2`}>
                {
                  dataNonEx.slice(0, PROMOTE.non_exc).map((val, index) => {
                    return (
                      <Col key={index} className={`pb-${BREAKPOINT}-4`}>
                        <Card
                          img={val.BANNERURL}
                          projectName={val.PROJECTNAME}
                          price={val.STARTINGPRICE}
                          shareCount={val.SHARETOTAL}
                          isSold={val.ISSOLDOUT}
                          onClick={handleOpen(val)}
                          className='bn-card-banner'
                        />
                      </Col>
                    )
                  })
                }
              </Row>
            )}
            {/* <div className={`py-3 text-center d-none d-${BREAKPOINT}-block`}>
              <Link to='/exclusive' className='btn btn-light rounded-pill px-5'>ดูดีลพิเศษเพิ่ม<FiArrowRight className='ms-1 mb-1' /></Link>
            </div> */}
          </Section>
          <Section
            title={(
              <>
                {/* <img src={`${process.env.PUBLIC_URL}images/harrison_s.png`} alt='harrison' className='bn-logo-hr me-2' /> */}
                บ้านสวยมือสอง
              </>
            )}
            // desc='เพียงแค่แชร์โปรเจคท์นี้ลงใน Social Media ตามลิงค์ที่ให้ไว้ด้านล่าง คุณก็สามารถรับค่านายหน้าได้'
            viewMore='/npa'
            showMoreButton
            loading={loading}
            bordered
            isEmpty
            isSlide
          >
            {dataNPA?.length > 0 && (
              <Row className={`row-cols-1 row-cols-${BREAKPOINT}-2`}>
                {
                  dataNPA.map((val, index) => {
                    return (
                      <Col key={index} className={`pb-${BREAKPOINT}-4`}>
                        <Card
                          img={val.BANNERURL}
                          projectName={val.PROJECTNAME}
                          price={val.STARTINGPRICE}
                          shareCount={val.SHARETOTAL}
                          isSold={val.ISSOLDOUT}
                          onClick={handleOpen(val)}
                          className='bn-card-banner'
                        />
                      </Col>
                    )
                  })
                }
              </Row>
            )}
            {/* <Banner
              title='All EXclusive'
              cta='ดูโครงการทั้งหมด'
              to='/exclusive'
              bg='bn-gradient-3'
              className={`mt-3 d-${BREAKPOINT}-none`}
            /> */}
            {/* <div className={`py-3 text-center d-none d-${BREAKPOINT}-block`}>
              <Link to='/npa' className='btn btn-light rounded-pill px-5'>ดูบ้านสวยมือสองเพิ่ม<FiArrowRight className='ms-1 mb-1' /></Link>
            </div> */}
          </Section>

          <Section
            title={(
              <>
                {/* <img src={`${process.env.PUBLIC_URL}images/harrison_s.png`} alt='harrison' className='bn-logo-hr me-2' /> */}
                ห้องเช่า
              </>
            )}
            // desc='เพียงแค่แชร์โปรเจคท์นี้ลงใน Social Media ตามลิงค์ที่ให้ไว้ด้านล่าง คุณก็สามารถรับค่านายหน้าได้'
            viewMore='/rental'
            showMoreButton
            loading={loading}
            isEmpty
            isSlide
          >
            {dataRental?.length > 0 && (
              <Row className={`row-cols-1 row-cols-${BREAKPOINT}-2`}>
                {
                  dataRental.map((val, index) => {
                    return (
                      <Col key={index} className={`pb-${BREAKPOINT}-4`}>
                        <Card
                          img={val.BANNERURL}
                          projectName={val.PROJECTNAME}
                          price={val.STARTINGPRICE}
                          shareCount={val.SHARETOTAL}
                          isSold={val.ISSOLDOUT}
                          isRent
                          onClick={handleOpen(val)}
                          className='bn-card-banner'
                        />
                      </Col>
                    )
                  })
                }
              </Row>
            )}
          </Section>

          {/* <Section
            title='ตารางนัดหมาย'
            viewMore='/appointment/active'
            loading={appointLoading}
            isEmpty
          >
            {appointList?.length > 0 && (
              <AppointList data={appointList.slice(0, 4)} displayOption={['name']} />
            )}
          </Section> */}

          <Section className='bn-gradient-1'>
            <Banner
              title='Q&A'
              cta='คำถามที่ถูกถามบ่อยที่สุด'
              to='/qa'
            // bg='bn-gradient-1'
            />
          </Section>

        </div>
      </Layout>
      <PopUp
        type={type}
        data={modalData}
        show={show}
        onClose={handleClose}
      />
    </>
  )
}

// const Msg = () => {
//   return (
//     <div className='-alert -alert-info mb-3 small'>
//       {/* <h4 className='alert-heading'>ระบบนายหน้าออนไลน์</h4> */}
//       เพียงแค่แชร์โปรเจคท์นี้ลงใน Social Media ตามลิงค์ที่ให้ไว้ด้านล่าง คุณก็สามารถรับค่านายหน้าได้
//       {/* <button type='button' className='btn-close' data-bs-dismiss='alert' aria-label='Close'></button> */}
//     </div>
//   )
// }

const Banner = ({ title, cta, to, bg, className }) => {
  return (
    <div className={classnames(
      'position-relative text-center',
      // 'mb-5 py-4 rounded-3',
      bg,
      className
    )}>
      <h2 className='display-2 mb-0 bn-fade text-uppercase'>{title}</h2>
      <Link to={to} className='stretched-link link-dark bn-fade'>{cta}<FiArrowRight className='ms-1 mb-1' /></Link>
    </div>
  )
}
