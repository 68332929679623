export default function DateFormatApp({ value, className }) {
  const d = new Date(value)
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  const mo = new Intl.DateTimeFormat('th-TH', { month: 'numeric' }).format(d)
  const da = new Intl.DateTimeFormat('th-TH', { day: 'numeric' }).format(d)
  const wd = new Intl.DateTimeFormat('th-TH', { weekday: 'short' }).format(d)
  return (
    <div className={`bn-date-fm-1 ${className}`}>
      <span>{da}</span>
      <small>{wd}<br />/{mo}/{ye}</small>
    </div>
  )
}
