import { useContext } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import Card from 'react-bootstrap/Card'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'
import DateFormatApp from './Date'
import { BREAKPOINT } from '../config'
import { useUtils } from '../services/utils'
import classnames from 'classnames'

const colWidth = {
  date: 3,
  name: 3,
  status: 2
}

export default function AppointList({ data, collapse, displayOption, children, className }) {
  return (
    <div className='py-3'>
      <Title display={displayOption} />
      <Accordion defaultActiveKey='0' className='border-top'>
        {
          data.map((val, index) => {
            return (
              <Card key={index}>
                {
                  collapse
                    ? <AccordionList
                      eventKey={`${index}`}
                      projectName={val.PROJECTNAME}
                      leadName={val.LEADNAME}
                      saleName={val.CONTACTSALENAME}
                      appointDate={val.APPOINTMENTDATE}
                      createDate={val.CREATEDATE}
                      finishDate={val.FINISHDATE}
                      status={val.APPOINTMENTSTATUS}
                      display={displayOption}
                    />
                    : <div className='d-flex align-items-center py-3'>
                      <ItemList
                        date={val.APPOINTMENTDATE}
                        projectName={val.PROJECTNAME}
                        leadName={val.LEADNAME}
                        saleName={val.CONTACTSALENAME}
                        status={val.APPOINTMENTSTATUS}
                        display={displayOption}
                      />
                    </div>
                }
              </Card>
            )
          })
        }
      </Accordion>
    </div>
  )
}

const Title = ({ display = [] }) => {

  const showName = display.includes('name')
  const showStatus = display.includes('status')

  return (
    <div className='row border-bottom g-0'>
      <span className={classnames(
        `col-${colWidth.date} col-${BREAKPOINT}-${(colWidth.date - 1)}`,
        showStatus && `offset-${BREAKPOINT}-${colWidth.status}`,
        `pe-2 pe-${BREAKPOINT}-0`,
        `text-end text-${BREAKPOINT}-start`,
        `fw-normal ps-4`
      )}>
        วันที่
      </span>
      <span className={`col ps-3 ps-${BREAKPOINT}-0 fw-normal`}>โครงการ/สถานที่</span>
      {
        showName && (
          <>
            <span className={`col-${colWidth.name} d-none d-${BREAKPOINT}-inline fw-normal`}>ลูกค้า</span>
            <span className={`col-${colWidth.name} d-none d-${BREAKPOINT}-inline fw-normal`}>เซลล์</span>
          </>
        )
      }
    </div>
  )
}

const ItemList = ({ date, projectName, leadName, saleName, status, display = [] }) => {

  const showName = display.includes('name')
  const showStatus = display.includes('status')
  const badge = status === 'ACTIVE'
    ? 'info'
    : status === 'SUCCESS'
      ? 'success'
      : 'danger'

  return (
    <>
      {
        showStatus && (
          <div className={`col-${colWidth.status} d-none d-${BREAKPOINT}-block text-center-`}>
            <span className={`badge rounded-pill bn-small bg-${badge}`}>{status}</span>
          </div>
        )
      }
      <div className={classnames(
        `col-${colWidth.date} col-${BREAKPOINT}-${(colWidth.date - 1)}`,
        `pe-2 pe-${BREAKPOINT}-0`
      )}>
        <div className={`float-end float-${BREAKPOINT}-none`}>
          <DateFormatApp value={date} />
        </div>
      </div>
      <div className={`col ps-3 ps-${BREAKPOINT}-0 lh-1`}>
        {projectName}
      </div>
      {
        showName && (
          <>
            <div className={`col-${colWidth.name} d-none d-${BREAKPOINT}-block lh-1`}>
              <span className='bn-fade'>{leadName}</span>
            </div>
            <div className={`col-${colWidth.name} d-none d-${BREAKPOINT}-block lh-1`}>
              <span className='bn-fade'>{saleName}</span>
            </div>
          </>
        )
      }
    </>
  )
}

const AccordionList = ({ eventKey, appointDate, createDate, finishDate, projectName, leadName, saleName, status, display }) => {
  const { dateConv } = useUtils()
  return (
    <>
      <ContextAwareToggle eventKey={`${eventKey}`}>
        <ItemList
          date={appointDate}
          projectName={projectName}
          leadName={leadName}
          saleName={saleName}
          status={status}
          display={display}
        />
      </ContextAwareToggle>
      <Accordion.Collapse eventKey={`${eventKey}`} className='card-body p-0'>
        <div className='py-3 -bn-gradient-1 bg-light'>
          <dl className='row g-0 mb-0'>
            <DescList dt='ลูกค้า' dd={leadName} />
            <DescList dt='เซลล์' dd={saleName} />
            <DescList dt='นัดหมาย' dd={dateConv(appointDate)} />
            <DescList dt='แจ้งเมื่อ' dd={dateConv(createDate)} />
            {status !== 'ACTIVE' && (
              <DescList dt='เสร็จสิ้น' dd={dateConv(finishDate)} />
            )}
            {/* <DescList dt={'addr'} dd={val.PROJECTFULLADDRESS} /> */}
            {/* <DescList dt='ลิงค์' dd={linkConv(val.PROJECTNAME, val.JOBID)} /> */}
          </dl>
        </div>
      </Accordion.Collapse>
    </>
  )
}

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext)
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  )
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <Card.Header
      onClick={decoratedOnClick}
      className='d-flex align-items-center'
    >
      {children}
      <div className='col-1 text-end'>
        {isCurrentEventKey
          ? <FiChevronUp className='text-secondary' />
          : <FiChevronDown className='text-secondary' />
        }
      </div>
    </Card.Header>
  )
}

const DescList = ({ dt, dd }) => {
  return (
    <>
      <dt className={classnames(
        `col-${colWidth.date} col-${BREAKPOINT}-${(colWidth.date - 2)}`,
        `offset-${BREAKPOINT}-${(colWidth.date + colWidth.status - 1)}`,
        `pe-2 pe-${BREAKPOINT}-0`,
        `text-end text-${BREAKPOINT}-start`,
        `bn-fade small`
      )}>
        {dt}
      </dt>
      <dd className={classnames(
        `col-${(12 - colWidth.date - colWidth.status)}`,
        `ps-3 ps-${BREAKPOINT}-0`,
        // 'bn-fade'
      )}>
        {dd}
      </dd>
    </>
  )
}
