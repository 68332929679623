import { useState, useContext, createContext } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { API_ENPOINT_URL, APP_ID, BN_ID } from '../config'
import { useUtils } from '../services/utils'
// import { Base64 } from 'js-base64'

export const authContext = createContext()
export function useAuth() {
  return useContext(authContext)
}

export function useProvideAuth() {

  const loggedIn = fbLoggedIn() || null
  const [user, setUser] = useState(loggedIn)
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn !== null)
  const [error, setError] = useState(false)
  const [msg, setMsg] = useState('')
  const [actionList, setActionList] = useState([])
  const [log, setLog] = useState(null)
  const { dateStamp, browserDetect } = useUtils()

  const actionLog = act => {
    if (actionList.includes(act)) {
      return false
    } else {
      actionList.push(act)
      setActionList(actionList)
      return true
    }
  }

  const signin = async (res, acc_type, cb) => {

    // console.log(res)
    if (res.accessToken) {

      const auth = {
        acc_type: acc_type,
        acc_name: res.name || '',
        email: res.email,
        pass: res.psw || '',
        picture: res.picture?.data?.url || '',
        id: res.id || '',
        path: res.path
      }

      const getData = await getAuth('authenticate', auth)

      if (getData?.data?.length) {
        saveData(getData?.data[0], auth)
        setIsLoggedIn(true)
        cb()
      } else if (getData?.data?.length === 0) {
        newUser(auth, (res) => {
          saveData(res, auth)
          setIsLoggedIn(true)
          cb()
        })
      } else {
        setMsg(`${getData}`)
        removeData()
      }

    } else {
      removeData()
    }

  }

  const signout = cb => {
    removeData()
    setError(false)
    cb()
  }

  const newUser = async (data, callback) => {
    if (data.acc_type === 'fb' || data.acc_type === 'bn') {
      const inserted = await getAuth('newuser', data)
      if (inserted) {
        const dataNew = await getAuth('authenticate', data)
        if (dataNew?.data?.length) {
          callback(dataNew?.data[0])
        } else {
          removeData()
        }
      } else {
        console.log("Can't add new user")
        removeData()
      }
    } else {
      removeData()
    }
  }

  const updateUser = async (data, callback) => {
    const newdata = {
      email: data.email || null,
      phone: data.phone || null
    }
    try {
      const res = await axios.put(`${API_ENPOINT_URL}upuser/${data.id}`, newdata)
      // if (res?.data?.length) {
      let updated = user
      updated.sent = newdata.email
      updated.phone = newdata.phone
      localStorage.setItem('p2s', JSON.stringify(updated))
      setUser(updated)
      callback(res)
      // console.log(user)
    } catch (err) {
      console.error(err)
      callback(0)
    }
  }

  const updateShareCount = () => {
    let newuser = user
    newuser.count = newuser.count + 1
    localStorage.setItem('p2s', JSON.stringify(newuser))
    setUser(newuser)
  }

  const newStamp = async (callback) => {
    let d = 0
    const data = await getData(`stamp/${user.id}`)
    if (data.length) {
      d = data[0]?.count
    } else {
      await getAuth(`stamp/create/${user.id}`)
    }
    let newuser = user
    newuser.stamp = d
    localStorage.setItem('p2s', JSON.stringify(newuser))
    setUser(newuser)
    callback()
  }

  const updateStampCount = async () => {
    const name = `_p2s${user?.id}`
    // let current = parseInt(Cookies.get(name))
    const time = new Date(new Date().setHours(24, 0, 0, 0))
    const stampCount = Cookies.get(name)
    if (typeof stampCount === 'undefined' || typeof user?.stamp === 'undefined') {
      newStamp(d => {
        if (typeof stampCount === "undefined") {
          Cookies.set(name, 1, { expires: time });
        }
        else{
          Cookies.set(name, parseInt(stampCount) + 1, { expires: time });
        }
      })
    } else {
      let current = parseInt(stampCount)
      if (current === 5) {
        console.log('stamped complete')
        return
      } else {
        current = current + 1
        Cookies.set(name, current,{expires: time})
        if (current === 5) {
          const newdata = {
            count: user?.stamp + 1
          }
          try {
            const res = await axios.put(`${API_ENPOINT_URL}stamp/update/${user?.id}`, newdata)
            if (res?.data?.length) {
              let updated = user
              updated.stamp = newdata.count
              localStorage.setItem('p2s', JSON.stringify(updated))
              setUser(updated)
            }
          } catch (err) {
            console.error(err)
          }
        }
      }
    }
  }

  const saveAccessLog = (via) => {
    if (log) {
      // console.log(via)
      const landing = (via === 'fb2' || via === 'bn2') ? '/login2' : '/login'
      let savelog = log
      savelog.accessBy = [via, landing]
      localStorage.setItem('p2sl', JSON.stringify(savelog))
      setLog(savelog)
    } else {
      console.log('log isn\'t set')
    }
  }

  const checkUserLog = async (location) => {
    // console.log(location)
    if (!log) {
      console.log('log has set')
      const p2sl = JSON.parse(localStorage.getItem(`p2sl`))
      if (!p2sl) {
        const crelog = await createUserLog()
        if (crelog) {
          localStorage.setItem('p2sl', JSON.stringify(crelog))
          setLog(crelog)
        }
      } else {
        setLog(p2sl)
      }
    } else {
      if (location === '/') {
        if (
          (log.accessBy[0] === 'fb' && log.date_login_fb === null)
          || (log.accessBy[0] === 'fb2' && log.date_login_fb2 === null)
          || (log.accessBy[0] === 'bn' && log.date_login_bn === null)
          || (log.accessBy[0] === 'bn2' && log.date_login_bn2 === null)
        ) {
          const uplog = await updateUserLog(log.accessBy[0], user.id)
          if (uplog) {
            localStorage.setItem('p2sl', JSON.stringify(uplog))
            setLog(uplog)
          }
          // } else if (log.accessBy[0] === 'fb2' && log.date_login_fb2 === null) {
          //   const uplogFB2 = await updateUserLog(log.accessBy[0])
          //   if (uplogFB2) {
          //     localStorage.setItem('p2sl', JSON.stringify(uplogFB2))
          //     setLog(uplogFB2)
          //   }
          // } else if (log.accessBy[0] === 'bn' && log.date_login_bn === null) {
          //   const uplogBN = await updateUserLog(log.accessBy[0])
          //   if (uplogBN) {
          //     localStorage.setItem('p2sl', JSON.stringify(uplogBN))
          //     setLog(uplogBN)
          //   }
          // } else if (log.accessBy[0] === 'bn2' && log.date_login_bn2 === null) {
          //   const uplogBN2 = await updateUserLog(log.accessBy[0])
          //   if (uplogBN2) {
          //     localStorage.setItem('p2sl', JSON.stringify(uplogBN2))
          //     setLog(uplogBN2)
          //   }
        } else {
          console.log('access completed')
        }
      }
    }
  }

  function createUserLog() {
    return (async () => {
      const ua = window.navigator
      const browser = browserDetect()
      const log = {
        guest: genrGuest(),
        date_visit: dateStamp(),
        // date_login: null,
        date_login_fb: null,
        date_login_fb2: null,
        date_login_bn: null,
        date_login_bn2: null,
        // access_by_fb: false,
        // access_by_fb2: false,
        // access_by_bn: false,
        // access_by_bn2: false,
        // user_id: null,
        ua: ua?.userAgent,
        ua_platform: ua?.platform,
        // ua_browser: ua?.appCodeName,
        ua_browser: browser.name,
        // ua_browser_version: ua?.appVersion,
        ua_browser_version: browser.version,
        ua_isMobile: ua?.userAgentData?.mobile
      }
      const insertLog = await getAuth('log/user/create', log)
      if (insertLog?.data?.length) {
        console.log('create log')
        return log
      } else {
        console.log('can\'t create log')
      }
    })()
  }

  function updateUserLog(via, userID) {
    return (async () => {
      let updateLog = log
      updateLog.user_id = userID
      updateLog.date_login = dateStamp()
      // updateLog.via = via
      const res = await axios.put(`${API_ENPOINT_URL}log/user/update/${log?.guest}`, updateLog)
      if (res?.data?.length) {
        console.log('user log updated')
        if (via === 'fb') updateLog.date_login_fb = dateStamp()
        if (via === 'fb2') updateLog.date_login_fb2 = dateStamp()
        if (via === 'bn') updateLog.date_login_bn = dateStamp()
        if (via === 'bn2') updateLog.date_login_bn2 = dateStamp()
        delete updateLog.user_id
        delete updateLog.date_login
        return updateLog
      } else {
        console.log('can\'t update user log')
      }
    })()
  }

  function saveData(data, auth) {
    let token = (auth.acc_type === 'fb')
      ? localStorage.getItem(`fblst_${APP_ID}`)
      : (auth.acc_type === 'bn')
        ? localStorage.getItem(`bn_${BN_ID}`)
        : null
    const update = {
      token: token,
      id: data.id,
      name: auth.acc_name,
      email: auth.email,
      picture: auth.picture || null,
      sent: data.sent,
      phone: data.phone,
      count: data.count,
      via: auth.acc_type,
      path: auth.path
    }
    localStorage.setItem('p2s', JSON.stringify(update))
    setUser(update)
  }

  function removeData() {
    setUser(null)
    setIsLoggedIn(false)
    setError(true)
    // setLog(null)
    sessionStorage.clear()
    // localStorage.clear()
    localStorage.removeItem('p2s')
    localStorage.removeItem(`fblst_${APP_ID}`)
  }

  return {
    user,
    signin,
    signout,
    updateUser,
    updateShareCount,
    updateStampCount,
    newStamp,
    isLoggedIn,
    actionLog,
    checkUserLog,
    saveAccessLog,
    log,
    error,
    msg
  }
}

const getData = async (api) => {
  let result = null
  try {
    const fetch = await axios(API_ENPOINT_URL + api)
    // console.log(fetch)
    result = fetch?.data
  } catch (err) {
    console.log(err)
    result = err
  }
  return result
}

const getAuth = async (api, auth) => {

  let result = null

  try {
    const fetch = await axios.post(API_ENPOINT_URL + api, auth)
    // console.log(fetch)
    result = fetch
  } catch (err) {
    console.log(err)
    result = err
  }

  return result
}

const fbLoggedIn = () => {
  return JSON.parse(localStorage.getItem(`p2s`))
}

function genrGuest() {
  const d = new Date()
  // const code = Math.floor(Math.random() * 100)
  // const id = Base64.encode(`${df}${code}`)
  const date = ("0" + d.getDate()).slice(-2)
  const month = ("0" + (d.getMonth() + 1)).slice(-2)
  const year = ('' + d.getFullYear()).slice(-3)
  return 'guest' + year + '' + month + '' + date + '' + d.getTime()
}
