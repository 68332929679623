import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../services/account'
import { useUtils } from '../services/utils'
import FacebookLogin from '../services/facebook'
import Loading from '../components/Loading'
import BaaniaLogin from '../components/BaaniaLogin'
import { APP_ID, BREAKPOINT, BN_ID } from '../config'

export default function Login() {

  const history = useHistory()
  const auth = useAuth()
  const [loading, setLoading] = useState(false)
  const { isApp } = useUtils()
  const useIsApp = false
  if (auth.msg) console.log(auth.msg)

  const onLoad = (cb) => {
    setLoading(true)
    setTimeout(() => {
      cb()
      setLoading(false)
    }, 3000)
  }

  const logout = () => {
    auth.signout(() => {
      console.log('sign off')
      history.replace('/login')
    })
  }

  const responseBaania = (response) => {
    onLoad(() => {
      auth.signin(response, 'bn', () => {
        history.replace('/')
      })
    })
  }

  const responseFacebook = (response) => {
    onLoad(() => {
      auth.signin(response, 'fb', () => {
        history.replace('/')
      })
    })
  }

  const accessBy = (via) => {
    // console.log(via)
    auth.saveAccessLog(via)
  }

  return (
    <div className='d-flex flex-column align-items-center'>
      <div className='col-md-8' style={{ maxWidth: 560 }}>
        <div className={`p-${BREAKPOINT}-4 text-center`}>
          <div className={`pt-5 pt-${BREAKPOINT}-3 mb-3`}>
            <h1>Test Login</h1>
          </div>
          {
            useIsApp
              ? (
                <div className='pt-4'>
                  <div className='alert alert-warning p-4- small'>
                    <strong>Please Open In Default Browser</strong>
                    <small className='d-block'>
                      กรุณาเปิดบนเบราว์เซอร์ หรือคัดลอกลิงก์ด้านล่างนี้</small>
                    <a href='https://prop2share.com' target='_blank' rel='noreferrer'><small className='d-block'>https://prop2share.com</small></a>
                  </div>
                </div>
              )
              : (
                <>
                  <p>
                    {
                      auth.error
                        ? <small className='text-danger'>ไม่สามารถเข้าสู่ระบบได้</small>
                        : !auth.isLoggedIn && <small className='text-muted invisible'>ล็อคอินเข้าสู่ระบบด้วยบัญชีของท่าน</small>
                    }
                  </p>
                  <div className='col-md-10 col-lg-8 mx-auto'>
                    <div className='d-grid gap-3'>
                      {
                        auth.isLoggedIn
                          ? (
                            <button type='button' className='btn btn-link' onClick={logout}>กลับหน้าแรก</button>
                          )
                          : (
                            <>
                              <FacebookLogin
                                appId={APP_ID}
                                callback={responseFacebook}
                                onClick={() => accessBy('fb2')}
                              />
                              <BaaniaLogin
                                bnID={BN_ID}
                                bnUrl='https://baania.dev/th/'
                                // bnUrl='http://localhost:8080/'
                                textButton='ดำเนินการต่อด้วย Baania'
                                callback={responseBaania}
                                onClick={() => accessBy('bn2')}
                              />
                              {/* <Link to='/landing'>landing</Link> */}
                              <span className='p-5 bn-small'>
                                {
                                  navigator.userAgent + ' [1]'
                                  || navigator.vendor + ' [2]'
                                  || window.opera + ' [3]'
                                }
                              </span>
                              <span className='p-5 bn-small'>
                                Is App ? {isApp() ? 'Yes' : 'No'}
                              </span>
                            </>
                          )
                      }
                    </div>
                  </div>
                </>
              )
          }
        </div>
      </div>
      {loading && <Loading />}
    </div>
  )
}
