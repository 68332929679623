export default function DownloadInfo({ files = [], className, classImg }) {
  return (
    <>
      <div className={className}>
        {
          files.map((img, i) => {
            return (
              <Photo key={i} number={(i + 1)} img={img} className={classImg} />
            )
          })
        }
      </div>
    </>
  )
}

const Photo = ({ number, img, className }) => {
  return (
    <div className={className}>
      <a href={img} download={`img-${number}.jpg`} target='_blank' rel='noreferrer'>
        <div className='ratio ratio-4x3 bg-light'>
          <img src={img} alt={'download file'} className='rounded' />
        </div>
      </a>
    </div>
  )
}
