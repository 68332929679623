import { useState } from 'react'

export default function usePop() {

  const [show, setShow] = useState(false)
  const [type, setType] = useState('')
  const [data, setData] = useState({})
  const onClose = () => setShow(false)
  const onOpen = (e, val, type) => {
    // console.log('click')
    e.preventDefault()
    setType(type)
    setData(val)
    setShow(true)
  }

  return {
    type,
    data,
    show,
    handleClose: onClose,
    handleOpen: (val) => {
      return {
        job: (e) => onOpen(e, val, 'job'),
        call: (e) => onOpen(e, val, 'call'),
        share: (e) => onOpen(e, val, 'share'),
        coupon: (e) => onOpen(e, val, 'coupon'),
        sharecoupon: (e) => onOpen(e,val, 'sharecoupon')

      }
    }
  }

}
