import { useState, useRef } from 'react'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import { FiSend } from 'react-icons/fi'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LineIcon
} from 'react-share'
import shared from '../services/shared'
import { useAuth } from '../services/account'
import { useUtils } from '../services/utils'
import { STAMP } from '../config'

export default function ShareInfo({ jobID, quote, placeholder, social }) {

  const shareTypes = [
    {
      name: 'fb',
      title: 'Facebook',
      icon: FacebookIcon,
      component: FacebookShareButton
    },
    {
      name: 'tw',
      title: 'Twitter',
      icon: TwitterIcon,
      component: TwitterShareButton
    },
    {
      name: 'li',
      title: 'Line',
      icon: LineIcon,
      component: LineShareButton
    }
  ]
  const { user, updateShareCount, updateStampCount } = useAuth()
  const { inTime, isApp } = useUtils()
  const [show, setShow] = useState(false)
  const target = useRef(null)
  const onClipboard = () => {
    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 3000)
  }
  const onShare = (url, social) => {
    shared(url, jobID, user?.id, social, () => {
      updateShareCount()
      if (STAMP.enabled && inTime(STAMP.dateStart, STAMP.dataEnd) && jobID != 67) {
        updateStampCount()
      }
    })
  }

  return (
    <>
      <div className="d-flex gap-2 align-items-center justify-content-center mb-4">
        <small className='me-2'><FiSend /> แชร์ไปที่</small>
        {
          shareTypes.map((share, i) => {
            if (!social[i]) return true
            let url = `${decodeURIComponent(social[i])}${user?.id}`
            // console.log(social[i])
            return (
              <share.component
                key={i}
                // title={share.name === 'li' ? quote : null}
                url={url}
                quote={quote}
                // via={quote}
                className='d-flex flex-column align-items-center'
                beforeOnClick={
                  isApp()
                    ? () => {
                      // isApp, webview
                      // console.log('before')
                      onShare(url, share.name)
                    }
                    : null
                }
                onShareWindowClose={() => onShare(url, share.name)}
              >
                <share.icon size={72} round={true} />
                {/* <small className='bn-small -bn-fade'>{share.title}</small> */}
              </share.component>
            )
          })
        }
      </div>
      {
        social[3] && (
          <div className="input-group">
            <input type="text" placeholder={`${decodeURIComponent(social[3])}${user.id}`} className="form-control form-control-sm rounded text-truncate" style={{ paddingRight: '4rem' }} disabled />
            <CopyToClipboard text={`${decodeURIComponent(social[3])}${user.id}`} onCopy={onClipboard}>
              <button ref={target} className="btn btn-sm btn-link bn-btn-copy">คัดลอก</button>
            </CopyToClipboard>
            <Overlay target={target.current} show={show} placement='bottom'>
              <Tooltip>คัดลอกแล้ว</Tooltip>
            </Overlay>
          </div>
        )
      }
    </>
  )
}
