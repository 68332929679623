import { useState, useEffect, useCallback } from 'react'
// import axios from 'axios'
import Cookies from 'js-cookie'
import classnames from 'classnames'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
// import { FiMail, FiPhone } from 'react-icons/fi'
// import { SiFacebook, SiLine } from 'react-icons/si'
import { useAuth } from '../services/account'
import { BREAKPOINT } from '../config'
// import { API_ENPOINT_URL, BREAKPOINT } from '../config'

const img_path = `${process.env.PUBLIC_URL}images/stamp/`

export default function StampCard({ className }) {

  const totalStamp = 25
  const rewardData = [
    {
      day: 7,
      name: "บัตรของขวัญ GrabFood",
      price: "100 บาท",
      title: "บัตรของขวัญ GrabFood มูลค่า 100 บาท",
      img: "GiftCard_Grab_100.png",
    },
    {
      day: 14,
      name: "บัตรโลตัส",
      price: "300 บาท",
      title: "บัตรของขวัญโลตัส มูลค่า 300 บาท",
      img: "GiftCard_Lotus_300.png",
    },
    {
      day: 21,
      name: "บัตรเติมน้ำมัน",
      price: "1,000 บาท",
      title: "บัตรเติมน้ำมัน PTT มูลค่า 1,000 บาท",
      img: "GiftCard_PTT_1000.png",
    },
    {
      day: 25,
      name: "บัตรเติมน้ำมัน",
      price: "1,500 บาท",
      title: "บัตรเติมน้ำมัน PTT มูลค่า 1,500 บาท",
      img: "GiftCard_PTT_1500.png",
    },
  ];
  const [days, setDays] = useState(0)
  const [show, setShow] = useState(false)
  const [showReward, setShowReward] = useState(false)
  const [isCompleteReward, setIsCompleteReward] = useState(false)
  const [reward, setReward] = useState([])
  const [loading, setLoading] = useState(false)
  const { user, newStamp } = useAuth()
  const currentReward = rewardData.filter(val => val.day === user?.stamp)

  const onClose = () => setShow(false)
  const onOpen = async (e, val, type) => {
    e.preventDefault()
    setShow(true)
    const current = Cookies.get(`_p2s${user?.id}`)
    const stamp = user?.stamp
    // console.log(current)
    if (typeof current === 'undefined' || typeof stamp === 'undefined') {
      setLoading(true)
      setTimeout(() => {
        newStamp(d => {
          const time = new Date(new Date().setHours(24, 0, 0, 0))
          // const time = new Date(new Date().getTime() + 1 * 60 * 1000)
          // console.log(time)
          if (typeof current === 'undefined'){
            Cookies.set(`_p2s${user?.id}`, 0, { expires: time });
          }else{
            setDays(parseInt(current));
          }
          setLoading(false)
        })
      }, 2000)
    } else {
      // console.log(current)
      setDays(parseInt(current))
    }
  }
  const onCloseReward = () => {
    setShowReward(false)
    setIsCompleteReward(true)
  }

  useEffect(() => {
    if (isCompleteReward) return
    // console.log('s1')
    if (!showReward && currentReward.length && days === 5) {
      // console.log('s2', currentReward)
      setTimeout(() => {
        setShowReward(true)
        setReward(currentReward[0])
      }, 400)
    }
  }, [days, showReward, isCompleteReward, currentReward])

  return (
    <>
      <a href='/#' onClick={e => onOpen(e)}>
        <img src={`${img_path}card_s.jpg`} width='100%' alt='stamp card' className={`d-${BREAKPOINT}-none img-fluid rounded`} />
        <img src={`${img_path}card.jpg`} width='100%' alt='stamp card' className={`d-none d-${BREAKPOINT}-block img-fluid rounded`} />
      </a>
      <Modal
        centered
        scrollable
        size='lg'
        show={show}
        onHide={onClose}
        // backdrop="static"
        keyboard={false}
        // dialogClassName={`align-items-end align-items-${BREAKPOINT}-center`}
        className='bn-modal'
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className='pb-0'>
          <div className='bn-modal-cover bg-dark mb-4'>
            <img src={`${img_path}card.jpg`} alt='stamp card' className='img-fluid' />
          </div>
          {
            loading
              ? <div className='my-5 p-5 text-center'>
                <Spinner
                  animation='border'
                  variant='secondary'
                  size='lg'
                />
                <div className='bn-small'>กำลังโหลด...</div>
              </div>
              : <StampArea
                data={rewardData}
                shared={days}
                current={user?.stamp}
                length={totalStamp}
                className={`mx-1 mx-sm-n3 mx-${BREAKPOINT}-3 mb-5`}
              />
          }
          <Condition className={`mx-n3 mx-sm-n5`} />
        </Modal.Body>
        <Reward
          item={reward}
          onClick={() => onCloseReward()}
          className={classnames(
            'fade',
            showReward && 'show'
          )}
        />

        {/* <Modal.Footer>
      </Modal.Footer> */}
      </Modal>
    </>
  )
}

const StampArea = ({ data, shared, current = 0, length, className }) => {

  const genrStamp = () => {
    let content = []
    for (let i = 0; i < length; i++) {
      const d = i + 1
      const reward = data.filter(val => val.day === d)
      content.push(
        <div key={i} className='col'>
          <div className={classnames(
            'bn-stamp_circle ratio ratio-1x1',
            d <= current && 'border-primary',
            ((d - current) === 1 && shared < 5) && 'border-warning'
          )}>
            <div className='position-absolute d-flex align-items-center justify-content-center'>
              <div className='text-center text-muted lh-1 small'>
                {
                  reward.length
                    ? (<>
                      <img
                        src={`${img_path}${reward[0].img}`}
                        alt='reward'
                        className='bn-stamp_reward'
                      />
                      <div className='small'>
                        {reward[0].name}<br />
                        <small>{reward[0].price}</small>
                      </div>
                    </>)
                    : (<>
                      {/* <span className='fs-3'>{d}</span>
                      <small className='d-block bn-small'>วัน</small> */}
                      {d <= current && (
                        <img
                          src={`${img_path}Stamp-6.png`}
                          alt='stamped'
                          className='bn-stamp_stamped'
                        />
                      )}
                      <div>
                        <span className='fs-3'>{d}</span>
                        <small className='d-block bn-small'>ดวง</small>
                      </div>
                    </>)
                }
              </div>
            </div>
          </div>
        </div>
      )
    }
    return content
  }

  return (
    <div className={className}>
      <div className={`d-flex flex-column flex-${BREAKPOINT}-row justify-content-${BREAKPOINT}-between align-items-${BREAKPOINT}-center mb-4 px-4 px-${BREAKPOINT}-0 py-${BREAKPOINT}-3 lh-1`}>
        <h5 className='py-1 mb-0'>สะสมแสตมป์ครบ <span className='text-info'>{current}</span> วัน</h5>
        <span className='py-1 small'>แชร์แล้ววันนี้ <span className='text-warning'>{shared}/5</span> ครั้ง</span>
      </div>
      {/* <hr className='mb-4' /> */}
      <div className={`row row-cols-5 gx-3 gx-${BREAKPOINT}-4 gy-3 gy-${BREAKPOINT}-4`}>
        {genrStamp()}
      </div>
    </div>
  )
}

const Condition = ({ className }) => {
  return (
    <div className={classnames('bn-gradient-3 p-5 small', className)}>
      <h5 className='mb-4'>กติกาการร่วมสนุก</h5>
      <p>
        ผู้ใช้งานต้องเข้าสู่ระบบและแชร์โครงการอย่างน้อยวันละ 5 โครงการ
        จึงจะได้รับแสตมป์ 1 ดวง
      </p>
      <ul className='fw-bold lh-sm mb-4 ps-4' style={{ listStyle: 'circle' }}>
        <li className='mb-3'>ครบ 7 ดวง รับบัตรของขวัญ GrabFood มูลค่า 100 บาท</li>
        <li className='mb-3'>ครบ 14 ดวง รับบัตรของขวัญโลตัส มูลค่า 300 บาท</li>
        <li className='mb-3'>
          ครบ 21 ดวง รับบัตรเติมน้ำมัน PTT มูลค่า 1,000 บาท
        </li>
        <li className='mb-3'>
          ครบ 25 ดวง รับบัตรเติมน้ำมัน PTT มูลค่า 1,500 บาท
        </li>
      </ul>
      <p>
        <strong>รางวัลพิเศษ</strong> : สำหรับผู้ที่ได้รับแสตมป์ครบ 25 ดวง 3
        ท่านแรก
      </p>
      <ul className='fw-bold lh-sm mb-4 ps-4' style={{ listStyle: 'circle' }}>
        <li className='mb-3'>รับบัตรเติมน้ำมัน PTT มูลค่า 5,000 บาท</li>
      </ul>
      <img
        src={`${img_path}GiftCard_PTT_5000.png`}
        alt='PTT_5000'
        className='img-fluid'
      />
      {/* <p className='text-muted small'>** ระยะเวลากิจกรรม : 1 มีนาคม - 31 มีนาคม 2565
      1.โดยผู้ใช้งานต้องตั้งโพสต์เป็นสาธารณะผ่านช่องทาง Facebook / LINE และ Twitter เท่านั้น ไม่รวมการคัดลอกลิ้งค์ (Copy Link)
      2.ผู้ที่เข้าร่วมกิจกรรมสามารถรับรางวัลได้เพียงรางวัลที่มีมูลค่าสูงสุดเพียง 1 รางวัลเท่านั้น
      3.หลังจากสิ้นสุดกิจกรรม บริษัทจะทำการส่งอีเมลแจ้งรายละเอียดแก่ผู้ได้รับรางวัลภายใน 15 วันทำการ ซึ่งผู้ใช้งานจะต้องยืนยันสิทธิ์หลังได้รับอีเมล์ผลประกาศรางวัลภายใน 5 วันนับแต่วันที่ได้รับอีเมลดังกล่าว หากผู้ใช้งานไม่ได้ติดต่อกลับมาภายในระยะเวลาที่กำหนดจะถือว่าผู้ใช้งานสละสิทธิ์
      4.ทีมงานมีสิทธิ์เด็ดขาดในการพิจารณาผู้เข้าร่วมกิจกรรมและมีสิทธิ์ที่จะเปลี่ยนแปลง แก้ไขหรือยกเลิกเงื่อนไขและ กฎกติกาต่าง ๆ ในการร่วมกิจกรรม
      5. การตัดสินของคณะกรรมการถือเป็นเด็ดขาดและสิ้นสุด ผู้เข้าร่วมกิจกรรมไม่มีสิทธิ์โต้แย้ง อุทธรณ์หรือขอให้มีการเปลี่ยนแปลงใด ๆ ทั้งสิ้นไม่ว่าด้วยเหตุผลใดก็ตาม
      6. ผู้ที่เข้าร่วมกิจกรรมจะไม่มีสิทธิ์เรียกร้องความเสียหายใดๆ จากการตัดสินของคณะกรรมการ ซึ่งการตัดสินพิจารณาของคณะกรรมการนั้นจะไม่มีผลต่อการที่จะต้องชดเชยค่าเสียหายใดๆให้แก่ ผู้ที่เกี่ยวข้อง
      7. ทีมงานขอสงวนสิทธิ์ในการเปลี่ยนแปลงของรางวัล โดยไม่ต้องแจ้งให้ทราบล่วงหน้า ในกรณีที่ ของรางวัลบางรายการหมด ทีมงานจะทำการชดเชย โดยการจัดของรางวัล ที่มีมูลค่าเทียบเท่าให้
      8. ของรางวัลไม่สามารถเปลี่ยนเป็นเงินสดหรือโอนสิทธิ์ให้แก่ผู้อื่นได้ในทุกกรณี
      9.เงื่อนไขเป็นไปตามที่บริษัทกำหนด</p> */}
      <p className='bn-small pt-4 border-top'>
        ระยะเวลากิจกรรม : 1 มีนาคม - 31 มีนาคม 2565
      </p>

      <ul className='bn-small lh-sm ps-3' style={{ listStyle: 'decimal' }}>
        <li className='mb-3'>
          โดยผู้ใช้งานต้องตั้งโพสต์เป็นสาธารณะผ่านช่องทาง Facebook / LINE และ
          Twitter เท่านั้น ไม่รวมการคัดลอกลิงค์ (Copy Link)
        </li>
        <li className='mb-3'>
          ผู้ที่เข้าร่วมกิจกรรมสามารถรับรางวัลได้เพียงรางวัลที่มีมูลค่าสูงสุดเพียง
          1 รางวัลเท่านั้น
        </li>
        <li className='mb-3'>
          หลังจากสิ้นสุดกิจกรรม
          บริษัทจะทำการส่งอีเมลแจ้งรายละเอียดแก่ผู้ได้รับรางวัลภายใน 15 วันทำการ
          ซึ่งผู้ใช้งานจะต้องยืนยันสิทธิ์หลังได้รับอีเมล์ผลประกาศรางวัลภายใน 5
          วันนับแต่วันที่ได้รับอีเมลดังกล่าว
          หากผู้ใช้งานไม่ได้ติดต่อกลับมาภายในระยะเวลาที่กำหนดจะถือว่าผู้ใช้งานสละสิทธิ์
        </li>
        <li className='mb-3'>
          ทีมงานมีสิทธิ์เด็ดขาดในการพิจารณาผู้เข้าร่วมกิจกรรมและมีสิทธิ์ที่จะเปลี่ยนแปลง
          แก้ไขหรือยกเลิกเงื่อนไขและ กฎกติกาต่าง ๆ ในการร่วมกิจกรรม
        </li>
        <li className='mb-3'>
          การตัดสินของคณะกรรมการถือเป็นเด็ดขาดและสิ้นสุด
          ผู้เข้าร่วมกิจกรรมไม่มีสิทธิ์โต้แย้ง
          อุทธรณ์หรือขอให้มีการเปลี่ยนแปลงใด ๆ ทั้งสิ้นไม่ว่าด้วยเหตุผลใดก็ตาม
        </li>
        <li className='mb-3'>
          ผู้ที่เข้าร่วมกิจกรรมจะไม่มีสิทธิ์เรียกร้องความเสียหายใดๆ
          จากการตัดสินของคณะกรรมการ
          ซึ่งการตัดสินพิจารณาของคณะกรรมการนั้นจะไม่มีผลต่อการที่จะต้องชดเชยค่าเสียหายใดๆให้แก่
          ผู้ที่เกี่ยวข้อง
        </li>
        <li className='mb-3'>
          ทีมงานขอสงวนสิทธิ์ในการเปลี่ยนแปลงของรางวัล
          โดยไม่ต้องแจ้งให้ทราบล่วงหน้า ในกรณีที่ ของรางวัลบางรายการหมด
          ทีมงานจะทำการชดเชย โดยการจัดของรางวัล ที่มีมูลค่าเทียบเท่าให้
        </li>
        <li className='mb-3'>
          ของรางวัลไม่สามารถเปลี่ยนเป็นเงินสดหรือโอนสิทธิ์ให้แก่ผู้อื่นได้ในทุกกรณี
        </li>
        <li className='mb-3'>เงื่อนไขเป็นไปตามที่บริษัทกำหนด</li>
      </ul>
      <p className='bn-small'>
        หมายเหตุ : หากพบปัญหาระหว่างการร่วมกิจกรรม กรุณาใช้ Browser อื่น ๆ
        เช่น Safari, Chrome, Firefox ใ่นการแชร์
      </p>
    </div>
  )
}

const Reward = ({ item, onClick, className }) => {
  return (
    <>
      <div className={classnames('bn-stamp_pop', className)}>
        <div className='card border-0'>
          <div className='card-body py-5 text-center'>
            <img src={`${img_path}${item?.img}`} alt='reward' className='img-fluid' />
            <span className='d-block mt-5 small'>คุณได้รับของรางวัล</span>
            <span className='d-block mb-3 fs-5 fw-bold'>{item?.title}</span>
            <button className='btn btn-primary rounded-pill px-4' onClick={onClick}>ตกลง</button>
          </div>
        </div>
      </div>
    </>
  )
}
