import { useRef, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Layout from '../components/Layout'
import AppointList from '../components/AppointList'
import Section from '../components/Section'
import useFetch from '../services/useFetch'
import { useAuth } from '../services/account'
import { API_ENPOINT_URL, BREAKPOINT } from '../config'

export default function Appointment() {

  const isMouted = useRef(true)
  const { state } = useParams()
  const { user } = useAuth()

  let url = `${API_ENPOINT_URL}appointment/agent/${user?.id}?state=`
  if (state === 'active') {
    url += 'ACTIVE'
  } else if (state === 'recent') {
    url += 'SUCCESS,CANCEL'
  } else {
    url = null
  }

  useEffect(() => {
    isMouted.current = true
  }, [state])

  // console.log(state, url, isMouted.current)
  const { data, loading } = useFetch(url, isMouted, [])

  return (
    <Layout
      fixed
      withBack
      header='การนัดหมาย'
    >
      <Container className='pt-4'>
        <div className={`bn-tabs d-flex align-items-center`}>
          <h1 className={`h2 d-none d-${BREAKPOINT}-block`}>การนัดหมาย</h1>
          <div className={`d-flex ms-auto gap-${BREAKPOINT}-3 flex-grow-1 flex-${BREAKPOINT}-grow-0`}>
            <Link
              to={`/appointment/active`}
              className={`bn-tabs-btn px-5 btn btn-${state !== 'active' ? 'outline-' : ''}primary`}
            >
              ปัจจุบัน
            </Link>
            <Link
              to={`/appointment/recent`}
              className={`bn-tabs-btn px-5 btn btn-${state === 'active' ? 'outline-' : ''}primary`}
            >
              ที่ผ่านมา
            </Link>
          </div>
        </div>
      </Container>
      <Section loading={loading} centered isEmpty>
        {data?.length && (
          <AppointList data={data} displayOption={['status']} collapse />
        )}
      </Section>
    </Layout>
  )
}