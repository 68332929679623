import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { component } from './routes'
import { IGNORE_PAGES } from './config'
import { authContext, useProvideAuth } from './services/account'
import AppService from './components/AppService'

export default function App() {

  const auth = useProvideAuth()
  const loc = window.location.pathname.split('/')
  const isIgnored = IGNORE_PAGES.includes(loc[1]) === true

  return (
    <authContext.Provider value={auth}>
      <BrowserRouter>
        <Switch>
          {
            component.map((route, i) => (
              <Route
                key={i}
                exact={route.exact}
                path={route.path}
                render={props => (
                  <route.component {...props} routes={route.routes} />
                )}
              />
            ))
          }
        </Switch>
        <AppService />
        {(!auth.isLoggedIn && !isIgnored) && <Redirect to='/landing' />}
      </BrowserRouter>
    </authContext.Provider>
  )
}
