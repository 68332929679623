import Spinner from "react-bootstrap/Spinner"

export default function Loading() {
    return (
        <div className="bn-loading">
            <Spinner animation="border" variant="light" size='lg'>
                {/* <span className="sr-only">Loading...</span> */}
            </Spinner>
        </div>
    )
}