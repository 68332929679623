export function useUtils() {

  const uid = (id) => {
    return `PS${('00000' + id).slice(-5)}`
  }

  const dateConv = (val) => {
    if (val) {
      const d = new Date(val)
      const hr = new Intl.DateTimeFormat('th-TH', { timeStyle: 'short', timeZone: 'UTC' }).format(d)
      const da = new Intl.DateTimeFormat('th-TH').format(d)
      return `${da}, ${hr} น.`
    }
    return '-'
  }

  const dateStamp = (val) => {
    const d = new Date(val || new Date())
    const date = ("0" + d.getDate()).slice(-2)            // adjust 0 before single digit date
    const month = ("0" + (d.getMonth() + 1)).slice(-2)    // current month
    const year = d.getFullYear()                          // current year
    const hours = ("0" + d.getHours()).slice(-2)          // current hours
    const minutes = ("0" + d.getMinutes()).slice(-2)      // current minutes
    const seconds = ("0" + d.getSeconds()).slice(-2)      // current seconds
    return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds
  }

  const inTime = (d1, d2) => {
    let check = new Date()
    check = Date.parse(check)
    const from = Date.parse(d1)
    const to = Date.parse(d2)
    // console.log(check, from, to)
    return (check <= to && check >= from)
  }

  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + '</a>'
    })
  }

  const thb = (p) => {
    return new Intl.NumberFormat(
      'th-TH',
      {
        style: 'currency',
        currency: 'THB'
      })
      .format(p)
      .replace(/\D00$/, '')
  }

  const isApp = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera
    return (
      ua.indexOf('FBAN') > -1 ||
      ua.indexOf('FBAV') > -1 ||
      ua.indexOf('Messenger') > -1 ||
      ua.indexOf('Line') > -1 ||
      ua.indexOf('Twitter') > -1 ||
      ua.indexOf('Instagram') > -1
    )
  }

  // const browserName = () => {
  //   const agent = window.navigator.userAgent.toLowerCase()
  //   // console.log(agent)
  //   switch (agent) {
  //     case agent.indexOf("edge") > -1: return "MS Edge"
  //     case agent.indexOf("edg/") > -1: return "Edge (chromium based)"
  //     case agent.indexOf("opr") > -1 && !!window.opr: return "Opera"
  //     case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome"
  //     case agent.indexOf("trident") > -1: return "MS IE"
  //     case agent.indexOf("firefox") > -1: return "Firefox"
  //     case agent.indexOf("safari") > -1: return "Safari"
  //     default: return "other"
  //   }
  // }

  const browserDetect = () => {
    // navigator.sayswho= (function(){
    const ua = window.navigator.userAgent
    let tem = ''
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return 'IE ' + (tem[1] || '')
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
    // return M.join(' ')
    // console.log(M)
    return {
      name: M[0],
      version: M[1]
    }
  }

  return {
    uid,
    dateConv,
    dateStamp,
    inTime,
    urlify,
    thb,
    isApp,
    browserDetect
  }

}
