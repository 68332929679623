import { FiPhone, FiShare } from 'react-icons/fi'
import { BREAKPOINT } from '../config'
import { useUtils } from '../services/utils'
import classnames from 'classnames'

export default function CardBanner({
  img,
  projectName,
  price,
  shareCount,
  isSold,
  isRent,
  onClick,
  className
}) {

  const { thb } = useUtils()
  const earnRentRate = price
  const earnRate = price / 1000000 * 2500

  return (
    <div
      className={classnames(
        'card overflow-hidden h-100',
        className
      )}
    >
      <div className='position-relative'>
        <a href='/#' onClick={onClick.job}>
          <div className='ratio ratio-fb bg-light overflow-hidden'>
            <img src={img} alt={projectName} />
          </div>
        </a>
        {
          isSold > 0 && <div className='bn-card-sold'><span>ขายแล้ว</span></div>
        }
      </div>
      <div className="card-body d-flex flex-column justify-content-between">
        <h5 className="card-title fw-normal mb-0">
          <a href='/#' onClick={onClick.job} className='link-dark'>{projectName}</a>
        </h5>
        <div className='bn-small mb-1'>
          {thb(price)}
          {isRent && '/เดือน'}
        </div>
        {
          !isSold
            ? (
              <div className='bn-card-banner-text d-flex align-items-center gap-2'>
                <div className='lh-1 text-info'>
                  <span className='bn-small'>
                    {
                      isRent
                        ? <>รับเงินการแชร์ต่อสัญญาเช่า 1 ปี</>
                        : <>รับเงินการแชร์ขั้นต่ำ</>
                    }
                  </span>
                  <span className='bn-card-banner-earn d-block fw-bold'>
                    {
                      isRent
                        ? <>{thb(earnRentRate)}</>
                        : <>{thb(earnRate)}++</>
                    }
                  </span>
                </div>
                <div className='bn-card-banner-btn d-flex gap-2 ms-auto'>
                  <button onClick={onClick.call} className={`btn btn-sm btn-light rounded-pill px-3 px-${BREAKPOINT}-4 text-truncate`}><FiPhone /> นัดหมาย</button>
                  <button onClick={onClick.share} className={`btn btn-sm btn-secondary -bn-btn-gradient rounded-pill px-3 px-${BREAKPOINT}-4 text-truncate`}><FiShare /> แชร์</button>
                </div>
              </div>
            )
            : <div className='text-muted'>-</div>
        }
      </div>
    </div>
  )
}

CardBanner.defaultProps = {
  isSold: false,
  isRent: false
}
